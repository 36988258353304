import React, { ReactElement, useEffect } from 'react';

import { ContentProvider } from '@interstellar/react-app-content';
import {
  Routes,
  Route,
  Navigate,
  useLocation,
} from '@interstellar/react-app-routing';
import { getMetaTagContent } from 'client/handlers/getMetaTagContent';
import { logTealiumPageViewEvent } from 'client/tealium/functions/logTealiumPageViewEvent';

import { AccessibilityPage } from './accessibility';
import { ArticleImprovingQualityBenefitsLandlordsAndTenantsPage } from './articleImprovingQualityBenefitsLandlordsAndTenants';
import { ArticleLetsGetRetrofitForTheFuturePage } from './articleLetsGetRetrofitForTheFuture';
import { ArticlesPage } from './articles';
import { BdmSearchPage } from './bdmSearch';
import { BuyToLetChangedBMSolutionsChangedWithItPage } from './buyToLetChangedBMSolutionsChangedWithIt';
import { CalculatorsPage } from './calculators';
import { ComplaintsPage } from './complaints';
import { ContactUsPage } from './contactUs';
import { CookiesPage } from './cookies';
import { DocumentUploadPage } from './documentUpload';
import { EducationalHubPage } from './educationalHub';
import { EpcRegulationsPage } from './epcRegulations';
import { EpcStepByStepGuidePage } from './epcStepByStepGuide';
import { ExistingCustomersPage } from './existingCustomers';
import { FeesPage } from './fees';
import { FreeConveyancingServicePage } from './freeConveyancingService';
import { FurtherAdvancesPage } from './furtherAdvances';
import { GrantsAndSchemesPage } from './grantsAndSchemes';
import { HomePage } from './home';
import { IntermediaryServiceSupportPage } from './intermediaryServiceSupport';
import { LandlordRetrofitToolPage } from './landlordRetrofitTool';
import { LowerRatesForEnergyEfficientHomesPage } from './lowerRatesForEnergyEfficientHomes';
import * as routes from './manifest';
import { NewBusinessEnquiriesPage } from './newBusinessEnquiries';
import { NotFoundPage } from './notFound';
import { OurCriteriaPage } from './ourCriteria';
import { PlacingBusinessPage } from './placingBusiness';
import { PortingPage } from './porting';
import { PrivacyPage } from './privacy';
import { ProductTransfersPage } from './productTransfers';
import { RateChangeCalculatorPage } from './rateChangeCalculator';
import { InternalRedirectDefinitions } from './redirectDefinitions';
import { RegisterPage } from './register';
import { ResourcesPage } from './resources';
import { SecurityPage } from './security';
import { SitemapPage } from './sitemap';
import { SubmittedMortgageApplicationsPage } from './submittedMortgageApplications';
import { SubmittingBtlOrLtbMortgageApplicationPage } from './submittingBtlOrLtbMortgageApplication';
import { SustainabilityHubPage } from './sustainabilityHub';
import { TermsAndConditionsPage } from './termsAndConditions';
import { TransferOrPurchaseOfEquityPage } from './transferOrPurchaseOfEquity';
import useAppConfig from '../components/appConfig/useAppConfig';
import content from '../content';
import { getThemeFromUrl } from '../utils/getThemeFromUrl';

// #pragma insert-page-component-import

function App(): ReactElement {
  const brandConfig = useAppConfig().BRAND_NAME;
  const brand = getThemeFromUrl() || brandConfig;

  const existingRoutes = Object.values(routes);
  const { pathname } = useLocation();

  useEffect(() => {
    const trackPageView = () => {
      if (existingRoutes.find((route) => route === pathname)) {
        logTealiumPageViewEvent({
          stepNumber: undefined,
          stepName: getMetaTagContent(pathname).title.split('|')[0].trim(),
        });
      }
    };
    trackPageView();
  }, [pathname, existingRoutes]);

  return (
    <Routes>
      <Route
        path={routes.Home}
        element={
          <ContentProvider value={content({ brand }).home}>
            <HomePage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.Resources}
        element={
          <ContentProvider value={content({ brand }).resources}>
            <ResourcesPage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.OurCriteria}
        element={
          <ContentProvider value={content({ brand }).ourCriteria}>
            <OurCriteriaPage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.Calculators}
        element={
          <ContentProvider value={content({ brand }).calculators}>
            <CalculatorsPage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.PlacingBusiness}
        element={
          <ContentProvider value={content({ brand }).placingBusiness}>
            <PlacingBusinessPage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.SustainabilityHub}
        element={
          <ContentProvider value={content({ brand }).sustainabilityHub}>
            <SustainabilityHubPage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.ContactUs}
        element={
          <ContentProvider value={content({ brand }).contactUs}>
            <ContactUsPage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.ExistingCustomers}
        element={
          <ContentProvider value={content({ brand }).existingCustomers}>
            <ExistingCustomersPage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.NewBusinessEnquiries}
        element={
          <ContentProvider value={content({ brand }).newBusinessEnquiries}>
            <NewBusinessEnquiriesPage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.Accessibility}
        element={
          <ContentProvider value={content({ brand }).accessibility}>
            <AccessibilityPage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.Privacy}
        element={
          <ContentProvider value={content({ brand }).privacy}>
            <PrivacyPage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.IntermediaryServiceSupport}
        element={
          <ContentProvider
            value={content({ brand }).intermediaryServiceSupport}
          >
            <IntermediaryServiceSupportPage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.Complaints}
        element={
          <ContentProvider value={content({ brand }).complaints}>
            <ComplaintsPage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.LowerRatesForEnergyEfficientHomes}
        element={
          <ContentProvider
            value={content({ brand }).lowerRatesForEnergyEfficientHomes}
          >
            <LowerRatesForEnergyEfficientHomesPage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.EpcRegulations}
        element={
          <ContentProvider value={content({ brand }).epcRegulations}>
            <EpcRegulationsPage />
          </ContentProvider>
        }
      />

      <Route
        path={routes.FreeConveyancingService}
        element={
          <ContentProvider value={content({ brand }).freeConveyancingService}>
            <FreeConveyancingServicePage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.LandlordRetrofitTool}
        element={
          <ContentProvider value={content({ brand }).landlordRetrofitTool}>
            <LandlordRetrofitToolPage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.EducationalHub}
        element={
          <ContentProvider value={content({ brand }).educationalHub}>
            <EducationalHubPage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.EpcStepByStepGuide}
        element={
          <ContentProvider value={content({ brand }).epcStepByStepGuide}>
            <EpcStepByStepGuidePage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.GrantsAndSchemes}
        element={
          <ContentProvider value={content({ brand }).grantsAndSchemes}>
            <GrantsAndSchemesPage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.SubmittingBtlOrLtbMortgageApplication}
        element={
          <ContentProvider
            value={content({ brand }).submittingBtlOrLtbMortgageApplication}
          >
            <SubmittingBtlOrLtbMortgageApplicationPage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.ProductTransfers}
        element={
          <ContentProvider value={content({ brand }).productTransfers}>
            <ProductTransfersPage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.FurtherAdvances}
        element={
          <ContentProvider value={content({ brand }).furtherAdvances}>
            <FurtherAdvancesPage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.TransferOrPurchaseOfEquity}
        element={
          <ContentProvider
            value={content({ brand }).transferOrPurchaseOfEquity}
          >
            <TransferOrPurchaseOfEquityPage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.TermsAndConditions}
        element={
          <ContentProvider value={content({ brand }).termsAndConditions}>
            <TermsAndConditionsPage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.Porting}
        element={
          <ContentProvider value={content({ brand }).porting}>
            <PortingPage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.Fees}
        element={
          <ContentProvider value={content({ brand }).fees}>
            <FeesPage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.DocumentUpload}
        element={
          <ContentProvider value={content({ brand }).documentUpload}>
            <DocumentUploadPage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.Cookies}
        element={
          <ContentProvider value={content({ brand }).cookies}>
            <CookiesPage />
          </ContentProvider>
        }
      />

      <Route
        path={routes.RateChangeCalculator}
        element={
          <ContentProvider value={content({ brand }).rateChangeCalculator}>
            <RateChangeCalculatorPage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.Security}
        element={
          <ContentProvider value={content({ brand }).security}>
            <SecurityPage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.Articles}
        element={
          <ContentProvider value={content({ brand }).articles}>
            <ArticlesPage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.SubmittedMortgageApplications}
        element={
          <ContentProvider
            value={content({ brand }).submittedMortgageApplications}
          >
            <SubmittedMortgageApplicationsPage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.BDMSearch}
        element={
          <ContentProvider value={content({ brand }).bdmSearch}>
            <BdmSearchPage />
          </ContentProvider>
        }
      />

      <Route
        path={routes.Register}
        element={
          <ContentProvider value={content({ brand }).register}>
            <RegisterPage />
          </ContentProvider>
        }
      />

      <Route
        path={routes.Sitemap}
        element={
          <ContentProvider value={content({ brand }).sitemap}>
            <SitemapPage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.ArticleImprovingQualityBenefitsLandlordsAndTenants}
        element={
          <ContentProvider
            value={
              content({ brand })
                .articleImprovingQualityBenefitsLandlordsAndTenants
            }
          >
            <ArticleImprovingQualityBenefitsLandlordsAndTenantsPage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.ArticleLetsGetRetrofitForTheFuture}
        element={
          <ContentProvider
            value={content({ brand }).articleLetsGetRetrofitForTheFuture}
          >
            <ArticleLetsGetRetrofitForTheFuturePage />
          </ContentProvider>
        }
      />
      <Route
        path={routes.BuyToLetChangedBMSolutionsChangedWithIt}
        element={
          <ContentProvider value={content({ brand }).buyToLetArticle}>
            <BuyToLetChangedBMSolutionsChangedWithItPage />
          </ContentProvider>
        }
      />

      {/* #pragma insert-page-route */}
      {
        // internal redirects
        Object.entries(InternalRedirectDefinitions).map(
          ([oldPath, newPath]) => (
            <Route
              key={oldPath}
              path={oldPath}
              element={<Navigate replace to={newPath} />}
            />
          ),
        )
      }
      {
        // internal redirects with trailing slash
        Object.entries(InternalRedirectDefinitions).map(
          ([oldPath, newPath]) => (
            <Route
              key={`${oldPath}/`}
              path={`${oldPath}/`}
              element={<Navigate replace to={newPath} />}
            />
          ),
        )
      }
      <Route
        path="*"
        element={
          <ContentProvider value={content({ brand }).notfound}>
            <NotFoundPage />
          </ContentProvider>
        }
      />
    </Routes>
  );
}

export default App;
