import { useEffect, useRef, useState } from 'react';

import { removeNonReactBodyElement } from './removeNonReactBodyElement';

const CRITERIA_SEARCH_SCRIPT_URL =
  'https://askus.halifax-intermediaries.co.uk/bmsolutions_live_emb/init.min.js';

export const SEARCH_ELEMENT_ID = 'virtual-assistant';
export const TARGET_SCRIPT_ID = 'criteria-loading-script';

// extend the window interface with config settings given to us from Creative Virtual
declare global {
  interface Window {
    cv_config: {
      enable_va: boolean;
      enable_livechat: boolean;
    };
  }
}

/**
 * The following hook dynamically loads a "target" script that will load a
 * "Criteria Search" button / element into the page.
 *
 * We do the following:
 * - wait for the document.readyState to be "complete"
 *     - track "readystatechange" event if necessary
 * - dynamically load the criteria script
 * - when the user leaves the page we
 *     - remove the criteria script loader
 *     - remove remove the visible search element from the page
 *
 * @param targetScriptUrl The url of the criteria page script to load
 */
export default function useCriteriaSearch(pageUrl: string) {
  const [readyState, setReadyState] = useState(document.readyState);
  const targetScript = useRef<HTMLScriptElement>(undefined);

  // init with settings given to us by Creative Virtual
  window.cv_config = {
    enable_va: true,
    enable_livechat: false,
  };

  const getCurrentReadyState = () => setReadyState(document.readyState);

  useEffect(() => {
    const searchElementExists = Boolean(
      document.getElementById(SEARCH_ELEMENT_ID),
    );

    if (
      !searchElementExists &&
      targetScript.current === undefined &&
      readyState === 'complete'
    ) {
      targetScript.current = document.createElement('script');

      targetScript.current.type = 'text/javascript';
      targetScript.current.src = CRITERIA_SEARCH_SCRIPT_URL;
      targetScript.current.id = TARGET_SCRIPT_ID;

      document.body.appendChild(targetScript.current);
    } else if (!searchElementExists && readyState !== 'complete') {
      // handle case where user does full page load -> wait for document to fully load
      document.addEventListener('readystatechange', getCurrentReadyState);
    }

    return () => {
      delete window.cv_config;

      document.removeEventListener('readystatechange', getCurrentReadyState);

      if (targetScript.current) {
        document.body.removeChild(targetScript.current);
        targetScript.current = null;
      }

      const searchElement = document.getElementById(SEARCH_ELEMENT_ID);

      if (searchElement) {
        searchElement.remove();
      } else {
        // handle edge case where user leaves page before criteria
        // script has loaded -> remove the element when it appears
        removeNonReactBodyElement(pageUrl, SEARCH_ELEMENT_ID);
      }
    };
  }, [readyState, pageUrl]);
}
