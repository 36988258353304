import React from 'react';

import { Paragraph, Strong } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import BM_Solutions_Head from 'assets/common/BM_Solutions_Head.png';
import ArticleView from 'client/components/articleView/articleView/ArticleView';
import GradientBox from 'client/components/gradientBox/GradientBox';

import { ArticleImprovingQualityBenefitsLandlordsAndTenantsContent } from './ArticleImprovingQualityBenefitsLandlordsAndTenants.config';

function BoxContent({ boxContent }) {
  return (
    <GradientBox>
      <Paragraph inverse data-testid="article-gradient-header">
        <Strong size="s4" inverse>
          {boxContent.oneInFiveHouseholdsLive}
        </Strong>
      </Paragraph>
      <Paragraph inverse data-testid="article-gradient-text1">
        {boxContent.andEveryOneOfThem}
      </Paragraph>
      <Paragraph
        inverse
        data-testid="article-gradient-text2"
      >{boxContent.theVastMajorityOfLandlords}</Paragraph>
      <Paragraph inverse data-testid="article-gradient-text3">
        {boxContent.boostingTheOverallQuality}
      </Paragraph>
      <Paragraph>
        <Strong size="s4" inverse data-testid="article-gradient-text4">
          {boxContent.soHowAreLandlordsDoing}
        </Strong>
      </Paragraph>
    </GradientBox>
  )
}

function ArticleImprovingQualityBenefitsLandlordsAndTenantsPage(): React.JSX.Element {
  const articleContent =
    useContent<ArticleImprovingQualityBenefitsLandlordsAndTenantsContent>();
  return (
    <ArticleView content={articleContent} imgSrc={BM_Solutions_Head} component={() => BoxContent(articleContent)} />
  );
}

export default ArticleImprovingQualityBenefitsLandlordsAndTenantsPage;