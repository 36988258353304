import React from 'react';

import { Box } from '@constellation/core';
import * as routes from 'client/routes/manifest';
import { AppContent } from 'content/AppContent';

import { MetaContentValues } from '../MetaContentValues';

const furtherAdvances: AppContent['furtherAdvances'] = {
  metaContent: MetaContentValues.FurtherAdvances,
  pageTitle: 'Further advances',
  backButtonLabel: 'Back',
  backButtonHref: routes.PlacingBusiness,
  infoPanel: {
    contentSections: [
      {
        sectionID: 'main-section',
        sectionBody: [
          {
            textWithLinks: [
              'You need to ',
              {
                displayText: 'sign in to Intermediaries online',
                href: 'https://www.bmsolutionsonline.co.uk/IntermediariesOnline/Login/Public/Login.aspx',
                external: true,
              },
              ' to submit an application.',
            ],
          },
          {
            textWithLinks: [
              "You'll need a 10-character user ID to sign in. If you don't have a user ID, please read our ",
              {
                displayText: 'registration guide',
                href: routes.Register,
              },
              '.',
            ],
          },
          { subheading: "When can my customers' apply for a further advance?" },
          {
            text: "Further advances can't be taken within six months of completion of the original mortgage.",
          },
          {
            text: 'An existing further advance must have completed at least 12 months ago before a new further advance application can be taken.',
          },
          {
            text: "If the customer hasn't made their normal monthly payment and is in arrears, they can't apply for a further advance until the arrears have been cleared. Their payments must also be made in full, on time, for three consecutive months.",
          },
          {
            text: 'We do not accept further advance applications where the EPC rating is F, G or an exemption is in place, except when the purpose of loan is for BTL repairs.',
          },
          { subheading: 'Before applying' },
          {
            text: "A further advance and a product transfer can't be applied for at the same time. If a product transfer application has been applied for, you'll need to wait until the new product has started before the further advance application can be submitted.",
          },
          {
            text: "If a product transfer application is submitted and subsequently cancelled, you'll need to wait until the 1st of the following month before applying for a further advance application.",
          },
          {
            textWithLinks: [
              'Lending is assessed using the rental income from the property. You can calculate how much your customer can borrow using our ',
              {
                displayText: 'rental income calculator.',
                external: true,
                href: 'https://www2.bmsolutionsonline.co.uk/tools/rental-income-calculator',
              },
            ],
          },
          {
            text: "To view the further advance products available for a specific customer, sign in and go to Mortgage enquiry. The Product finder tab will show the customer's available products. Use the calculator on the right of the Product finder to add the additional borrowing amount required. You can refresh the screen to update the available products.",
          },
          { subheading: 'Valuations' },
          {
            text: 'You can find out the current valuation of the property in the Summary of accounts screen at the start of the further advance application.',
          },
          {
            text: "If the additional borrowing exceeds 75% of the current index valuation, we'll carry out a valuation.",
          },
          {
            textWithLinks: [
              "If the borrowing doesn't exceed 75% of the current index valuation and the applicants' would like to pay for a revaluation of the property, you'll need to complete a ",
              {
                displayText: 'revaluation authority form',
                external: true,
                href: 'https://www.bankofscotland.co.uk/mortgages/existing-customers/bm-pt-revaluation-authority-form.html?optoutmulti=0:0|c1:1|c3:0|c5:0|c4:0|c2:0&optmessage=1&optoutmulti=0:0|c1:1|c3:0|c5:0|c4:0|c2:0&optmessage=1',
              },
              ' before submitting the application.',
            ],
          },
          {
            container: ({ children }) => (
              <Box bgColor="none" padding="none">
                {children}
              </Box>
            ),
            link: {
              displayText: 'View our revaluation fees.',
              href: routes.Fees,
            },
          },
          { subheading: 'Submitting a further advance' },
          {
            textWithLinks: [
              "You'll need to complete our ",
              {
                displayText: 'data capture form',
                external: true,
                href: '/public/fa-data-capture-form.pdf',
              },
              ' to submit a further advance application.',
            ],
          },
          {
            textWithLinks: [
              "If you get a message about needing to update a customer's account while keying a further advance, you'll need to ",
              {
                displayText: 'complete this form',
                external: true,
                href: 'https://bankofscotland.co.uk/mortgages/existing-customers/account-update-submit-pt-fad.html?optoutmulti=0:0|c1:1|c3:0|c5:0|c4:0|c2:0&optmessage=1',
              },
              '.',
            ],
          },
        ],
      },
    ],
  },
};

export default furtherAdvances;
