import React from 'react';

import { Hr } from '@constellation/core';
import { contentSectionBox } from 'client/components/infoPanel/InfoPanel.config';
import * as routes from 'client/routes/manifest';
import { AppContent } from 'content/AppContent';

import { MetaContentValues } from '../MetaContentValues';

const register: AppContent['register'] = {
  metaContent: MetaContentValues.Register,
  pageTitle: 'Register',
  backButtonLabel: 'Back to Placing business',
  backButtonHref: routes.PlacingBusiness,
  test: 'test',

  infoPanel: {
    contentSections: [
      {
        sectionID: 'main-register-section',
        isAccordion: false,
        sectionHeading: 'Register with us',
        headingLevel: 'h2',
        sectionBody: [
          {
            text: "Your company must be registered on our panel to place business with us. To do this, follow the steps in the next section and we'll process your application. It normally takes around 48 hours.",
          },
          {
            text: "Once your company's registered, you can set up an individual account.",
          },
        ],
      },
      {
        isAccordion: true,
        sectionID: 'register-your-company',
        headingLevel: 'h3',
        sectionHeading: 'Register your company or amend company details',
        sectionBody: [
          {
            text: 'Please follow these steps to register a new company to us. You can also use this page to amend your company details.',
          },
          {
            text: "If you're already set up with BM Solutions and you need to request an additional payment route or amend your details, please make sure you put this as your request type.",
          },
          {
            textWithLinks: [
              'Please email ',
              {
                displayText: 'bmsolutions@lloydsbanking.com',
                href: 'mailto:bmsolutions@lloydsbanking.com',
                external: false,
              },
              ' with the following. All details are mandatory unless otherwise stated.',
            ],
          },
          {
            boldText: '1. Type of request - please state one:',
          },
          {
            container: contentSectionBox({ marginBottom: '05' }),
            list: [
              'New firm to BM Solutions.',
              'Additional payment route.',
              'Change of address.',
              'Change of trading name.',
              'Change of principal.',
            ],
          },
          {
            boldText: '2. Personal details',
          },
          {
            textWithLinks: [
              'Before providing the personal information below, please view our ',
              {
                displayText: 'Privacy notice',
                href: routes.Privacy,
                external: false,
              },
              '.',
            ],
          },
          {
            boldText: 'Provide all of the following:',
          },
          {
            container: contentSectionBox({ marginBottom: '05' }),
            list: [
              'First name.',
              'Last name.',
              'Company name - please only include one company name you want to use.',
              'Full address.',
              'Telephone number.',
              'Email address.',
              'Director full name (only required for new directly authorised/principal firms).',
              'Director email address (only required for new directly authorised/principal firms).',
              'Compliance officer full name, if different from director (only required for new directly authorised/principal firms).',
              'Compliance officer email address, if different from director (only required for new directly authorised/principal firms).',
              'Name of business development manager, if known.',
              'Name of your mortgage club/payment route(s)*.',
            ],
          },
          {
            text: "*If you're a registered appointed representative, please make sure the required payment route has been authorised by your principal.",
          },
          {
            boldText: '3. Mortgage regulation permissions',
          },
          {
            text: 'Appointed representative – please provide:',
          },
          {
            container: contentSectionBox({ marginBottom: '05' }),
            list: [
              'Company FCA number.',
              'Principal FCA number.',
              'Principal name.',
            ],
          },
          {
            text: 'Directly authorised – please provide:',
          },
          {
            container: contentSectionBox({ marginBottom: '05' }),
            list: ['Company FCA number.'],
          },
          {
            text: "In certain cases, we might need more information to complete our processes. If this is the case, we'll contact you by phone or email.",
          },
          {
            boldText: 'Important',
          },
          {
            text: 'Failure to provide this information or withdrawal of your application might jeopardise your relationship with other Lloyds Banking Group panels.',
          },
          {
            text: "Email messages passing over the internet aren't secure and could be intercepted and read by someone else. Please only include the information requested and check the recipient's email address is correct before sending.",
          },
        ],
      },
      {
        sectionID: 'margin-1',
        sectionBody: [
          {
            component: <Hr marginTop="none" />,
          },
        ],
      },
      {
        sectionID: 'set-up-individual-account',
        sectionHeading: 'Set up an individual account',
        headingLevel: 'h2',
        sectionBody: [
          {
            text: "Please make sure your company is registered on our panel before completing this step. You'll receive a user ID, which you'll need to make a note of as we'll ask you for it to activate your account.",
          },
          {
            linkButton: {
              href: 'https://www.bmsolutionsonline.co.uk/IntermediariesOnline/Login/Public/Login.aspx',
              external: true,
              displayText: 'Set up individual account',
            },
          },
        ],
      },
      {
        sectionID: 'activate-your-account',
        sectionHeading: 'Activate your account',
        headingLevel: 'h2',
        sectionBody: [
          {
            text: "You'll need your user ID and one-time password we emailed you.",
          },
          {
            linkButton: {
              href: 'https://www.bmsolutionsonline.co.uk/IntermediariesOnline/Login/Public/Login.aspx',
              external: true,
              displayText: 'Activate account',
            },
          },
        ],
      },
    ],
  },
};

export default register;
