import React from 'react';

import {
  ContentGroup,
  Heading,
  Main,
  Paragraph,
  Strong,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import contentBoxImage from 'assets/common/BM_illustration_chart_white.png';
import GradientBox from 'client/components/gradientBox/GradientBox';
import MetaTags from 'client/components/metaTags/MetaTags';
import { NavigationTiles } from 'client/components/navigationTiles';
import RedGradientBox from 'client/components/redGradientBox/RedGradientBox';

import { ArticlesContent } from './Articles.config';

function ArticlesPage(): React.JSX.Element {
  const { metaContent, pageTitle, navigationTiles, secondaryTitle } =
    useContent<ArticlesContent>();

  return (
    <Main>
      <MetaTags metaContent={metaContent} />

      <GradientBox>
        <RedGradientBox
          imgSrc={contentBoxImage}
          imgAlt="Icon of whiteboard with images on"
        >
          <Heading
            as="h1"
            size="s7"
            data-testid="articles-heading"
            marginBottom="03"
            inverse
          >
            {pageTitle}
          </Heading>
          <Paragraph data-testid="articles-subheading">
            <Strong inverse>{secondaryTitle}</Strong>
          </Paragraph>
        </RedGradientBox>
      </GradientBox>
      <ContentGroup marginTop="05">
        <NavigationTiles
          navigationTiles={navigationTiles}
          pageTestId="articles-page-navigation-tiles"
          layout="list"
        />
      </ContentGroup>
    </Main>
  );
}

export default ArticlesPage;
