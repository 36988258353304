import React from 'react';

import {
  ContentGroup,
  DisplayText,
  Grid,
  GridItem,
  Heading,
  Hr,
  Main,
  Strong,
} from '@constellation/core';
import { InfoPanel } from 'client/components/infoPanel';
import MetaTags from 'client/components/metaTags/MetaTags';
import { StyledLink } from 'client/components/styledLink/StyledLink';
import { ArticleImprovingQualityBenefitsLandlordsAndTenantsContent } from 'client/routes/articleImprovingQualityBenefitsLandlordsAndTenants/ArticleImprovingQualityBenefitsLandlordsAndTenants.config';
import { ArticleLetsGetRetrofitForTheFutureContent } from 'client/routes/articleLetsGetRetrofitForTheFuture/ArticleLetsGetRetrofitForTheFuture.config';
import { BuyToLetChangedBMSolutionsChangedWithItContent } from 'client/routes/buyToLetChangedBMSolutionsChangedWithIt/BuyToLetChangedBMSolutionsChangedWithIt.config';

export interface ArticleViewProps {
  content:
    | ArticleImprovingQualityBenefitsLandlordsAndTenantsContent
    | BuyToLetChangedBMSolutionsChangedWithItContent
    | ArticleLetsGetRetrofitForTheFutureContent;

  imgSrc: any;
  component?: any;
}

function ArticleView({
  content,
  imgSrc,
  component: Component,
}: ArticleViewProps): React.JSX.Element {
  const {
    backButtonLabel,
    backButtonHref,
    infoPanel,
    pageTitle,
    pageTitleTwo,
    metaContent,
    imgAltText,
    imgLine1,
    imgLine2,
  } = content;

  return (
    <Main>
      <MetaTags metaContent={metaContent} />
      <ContentGroup marginTop="05">
        <StyledLink
          iconPosition="left"
          data-testid="back-button-link"
          to={backButtonHref}
        >
          {backButtonLabel}
        </StyledLink>
        <Heading as="h1" size="s7" marginTop="05" data-testid="article-title">
          {pageTitle}
        </Heading>
        {pageTitleTwo && (
          <Heading as="h2" size="s5">
            {pageTitleTwo}
          </Heading>
        )}
        <Grid>
          <GridItem xs="auto" sm="auto">
            <img src={imgSrc} alt={imgAltText} />
          </GridItem>
          <GridItem xs="auto" sm="auto">
              <Strong>
                <DisplayText size="s8" marginBottom="none">
                  {imgLine1}
                </DisplayText>
              </Strong>
              <DisplayText size="s10">{imgLine2}</DisplayText>
          </GridItem>
        </Grid>
        <Hr />
        <Grid>
          <GridItem sm={12} md={8}>
            {Component && <Component />}
            <InfoPanel
              infoPanel={infoPanel}
              pageTestIds={{
                sectionHeading: 'article-view-heading',
                sectionBody: 'article-view-body',
              }}
            />
          </GridItem>
        </Grid>
      </ContentGroup>
    </Main>
  );
}

export default ArticleView;
