import React from 'react';

import {
  Accordion,
  ContentGroup,
  Grid,
  GridItem,
  Heading,
  Main,
  Paragraph,
  Strong,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import MetaTags from 'client/components/metaTags/MetaTags';
import { StyledLink } from 'client/components/styledLink/StyledLink';
import testIds from 'client/testIds';

import { ExistingCustomersContent } from './ExistingCustomers.config';

function ExistingCustomersPage(): React.JSX.Element {
  const {
    backButtonHref,
    backButtonLabel,
    metaContent,
    section1,
    accordion1,
    accordion2,
    accordion3,
    accordion4,
    accordion5,
    accordion6,
    accordion7,
  } = useContent<ExistingCustomersContent>();

  return (
    <Main>
      <MetaTags metaContent={metaContent} />

      <ContentGroup marginTop="05">
        <StyledLink iconPosition="left" to={backButtonHref}>
          {backButtonLabel}
        </StyledLink>
        <Heading as="h1" size="s6" marginTop="05">
          {section1.heading}
        </Heading>
        <Paragraph>
          <Strong>{section1.subheading}</Strong>
        </Paragraph>
        <Grid>
          <GridItem sm={12} md={8}>
            <Paragraph>
              {section1.text1}
              <StyledLink
                to={section1.text1TelHref}
                data-testid={
                  testIds.existingCustomers.updatingInfoPleaseCallTel
                }
              >
                {section1.text1Tel}
              </StyledLink>
              .
            </Paragraph>
            <Paragraph>{section1.text2}</Paragraph>
            <Accordion
              role="button"
              variation="inpage1"
              data-testid={
                testIds.existingCustomers.convertingCustomerAccountNumbers
                  .accordion
              }
              label={accordion1.label}
            >
              <Paragraph>{accordion1.text}</Paragraph>
              <Accordion
                role="button"
                variation="inpage1"
                label={accordion2.label}
                data-testid={
                  testIds.existingCustomers.ifTheAccountNumberHas12Digits
                    .accordion
                }
              >
                <Paragraph>{accordion2.text}</Paragraph>
                <Paragraph>
                  <Strong>{accordion2.exampleLabel}</Strong>
                </Paragraph>
                <Paragraph>{accordion2.exampleText}</Paragraph>
              </Accordion>
              <Accordion
                role="button"
                variation="inpage1"
                label={accordion3.label}
                data-testid={
                  testIds.existingCustomers
                    .ifAccountNumber11DigitsOnly1DigitAfterHash.accordion
                }
              >
                <Paragraph>{accordion3.text}</Paragraph>
                <Paragraph>
                  <Strong>{accordion3.exampleLabel}</Strong>
                </Paragraph>
                <Paragraph>{accordion3.exampleText}</Paragraph>
              </Accordion>
              <Accordion
                role="button"
                variation="inpage1"
                label={accordion4.label}
                data-testid={
                  testIds.existingCustomers
                    .ifAccountNumberHas11Digits2DigitsAfterDash.accordion
                }
              >
                <Paragraph>{accordion4.text}</Paragraph>
                <Paragraph>
                  <Strong>{accordion4.exampleLabel}</Strong>
                </Paragraph>
                <Paragraph>{accordion4.exampleText}</Paragraph>
              </Accordion>
              <Accordion
                role="button"
                variation="inpage1"
                label={accordion5.label}
                data-testid={
                  testIds.existingCustomers.ifAccountNumberHas10Digits.accordion
                }
              >
                <Paragraph>{accordion5.text}</Paragraph>
                <Paragraph>
                  <Strong>{accordion5.exampleLabel}</Strong>
                </Paragraph>
                <Paragraph>{accordion5.exampleText}</Paragraph>
              </Accordion>
            </Accordion>
            <Accordion
              role="button"
              variation="inpage1"
              label={accordion6.label}
              data-testid={
                testIds.existingCustomers.payingCustomerErcs.accordion
              }
            >
              <Paragraph>
                {accordion6.text}
                <StyledLink
                  to={accordion6.linkHref}
                  external
                  data-testid={
                    testIds.existingCustomers.payingCustomerErcs.link
                  }
                >
                  {accordion6.linkText}
                </StyledLink>
                .
              </Paragraph>
              <Paragraph>
                <Strong>{accordion6.importantLabel}</Strong>
              </Paragraph>
              <Paragraph>
                {accordion6.importantText}
                <StyledLink
                  to={accordion6.importantTelHref}
                  data-testid={testIds.existingCustomers.payingCustomerErcs.tel}
                >
                  {accordion6.importantTel}
                </StyledLink>
                .
              </Paragraph>
            </Accordion>
            <Accordion
              role="button"
              variation="inpage1"
              label={accordion7.label}
              data-testid={
                testIds.existingCustomers.updatingProductTransfersFurtherAdvances.accordion
              }
            >
              <Paragraph>
                {accordion7.text1}
                <StyledLink
                  to={accordion7.linkHref}
                  external
                  data-testid={testIds.existingCustomers.updatingProductTransfersFurtherAdvances.link}
                >
                  {accordion7.linkText}
                </StyledLink>
                .
              </Paragraph>
              <Paragraph>
                <Strong>{accordion7.subheading}</Strong>
              </Paragraph>
              <Paragraph>{accordion7.text2}</Paragraph>
              <Paragraph>
                <StyledLink
                  to={accordion7.goToSchemeLink}
                  external
                  data-testid={testIds.existingCustomers.updatingProductTransfersFurtherAdvances.amendmentLink}
                >
                  {accordion7.goToSchemeText}
                </StyledLink>
              </Paragraph>
            </Accordion>
          </GridItem>
        </Grid>
      </ContentGroup>
    </Main>
  );
}

export default ExistingCustomersPage;
