import './public-path';
import './utils/csrf/csrf';

import React from 'react';

import { BrowserRouter } from '@interstellar/react-app-routing';
import App from 'client/components/app/App';
import AppConfigContext from 'client/components/appConfig/AppConfigContext';
import { createRoot, hydrateRoot } from 'react-dom/client';

const container = document.getElementById('app');

const {appConfig} = window;
const { ssr, basePath } = appConfig;

const app = (
  <AppConfigContext.Provider value={appConfig as any}>
    <BrowserRouter basename={basePath}>
      <App />
    </BrowserRouter>
  </AppConfigContext.Provider>
);

if (ssr) {
  hydrateRoot(container, app);
} else {
  const root = createRoot(container);
  root.render(app);
}
