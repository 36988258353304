import * as routes from 'client/routes/manifest';
import { AppContent } from 'content/AppContent';

import { MetaContentValues } from '../MetaContentValues';

const buyToLetChangedBMSolutionsChangedWithIt: AppContent['buyToLetArticle'] = {
  metaContent: MetaContentValues.BuyToLetChangedBmSolutionsChangedWithItPage,
  pageTitle: 'Buy-to-let changed. BM Solutions changed with it.',
  pageTitleTwo:
    'Forget the new normal. The market is constantly evolving and so are we.',
  backButtonLabel: 'Back',
  backButtonHref: routes.Articles,
  imgAltText: 'Head of BM Solutions',
  imgLine1: 'Leigh Church',
  imgLine2: 'Head of BM Solutions',
  boxContent: {
    paragraphone:
      "The buy-to-let market doesn't stand still and neither does BM Solutions.",
    paragraphtwo:
      'There is no new normal, only adaptation and continual change.',
    paragraphthree:
      "That's the sign of a thriving, competitive sector, despite ongoing economic challenges.",
    paragraphfour:
      'BM Solutions has always been committed to keeping pace with market changes and frequently leading the way.',
    paragraphfive:
      "As the UK's largest buy-to-let lender, we know it's important we don't rest on our laurels.",
    paragraphsix: `But that doesn't mean we should change for change's sake. We listen to landlords and brokers. You know your clients best and you understand what they need from a lender, in the good times and the more challenging ones.`,
  },
  infoPanel: {
    contentSections: [
      {
        sectionID: 'Tough times',
        isAccordion: false,
        sectionHeading: 'Tough times',
        headingLevel: 'h2',
        sectionBody: [
          {
            boldText:
              "Let's be honest, 2023 was difficult for brokers, landlords and renters.",
          },
          {
            textWithLinks: [
              'Higher rates, tighter criteria and significantly lower volumes took their toll on those involved in the buy-to-let sector. ',
              {
                displayText:
                  'Purchase lending fell by 53% to £8bn in 2023, according to UK Finance',
                href: 'https://www.ukfinance.org.uk/news-and-insight/press-release/mortgage-lending-fall-in-2024',
                external: true,
              },
              ', and is forecast to fall further this year.',
            ],
          },
          {
            text: "Continued high mortgage rates are making landlords assess and optimise their balance sheets. And, while the Renters Reform Bill didn't make it through wash-up, many landlords, brokers and housing professionals await the next Government's plans for the sector.",
          },
          {
            text: 'However, 2024 has started much stronger than anyone could have predicted, or hoped, for the buy-to-let market.',
          },
          {
            textWithLinks: [
              {
                displayText:
                  "The Consumer Prices Index is within spitting distance of The Bank of England's target, at 2.3%",
                href: 'https://www.ons.gov.uk/economy/inflationandpriceindices/bulletins/consumerpriceinflation/april2024',
                external: true,
              },
              ', down from 11% in Autumn 2022, which could potentially mean a more favourable rate environment later this year.',
            ],
          },
          {
            text: "At BM Solutions, we've seen business levels rise in the first quarter of the year, way above our expectations.",
          },
          {
            text: 'This is partly down to market buoyancy, of course, but some of it is directly due to changes we made last year to support landlords with the affordability squeeze.',
          },
          {
            text: 'Those changes were in response to your feedback from our regular broker research and listening events, as well as from engaging with landlords and industry experts.',
          },
        ],
      },
      {
        sectionID: "What's new?",
        isAccordion: false,
        sectionHeading: "What's new?",
        headingLevel: 'h2',
        sectionBody: [
          {
            boldText:
              "Below are just some of the many changes we've made to enhance our market-leading proposition over the last 18 months.",
          },
          {
            boldText: 'Products',
          },
          {
            list: [
              "Introduced top slicing, boosting your landlord clients' borrowing power.",
              'Improved our stress rates five times last year, to support more landlords to invest in the sector.',
              "Launched new large loan products as part of our commitment to meeting the diverse needs of borrowers in this underserved segment. We'll allow lending up to £2m, supporting rental investment in higher-value markets.",
              'Designed a range of new products at differing fee points to offer more flexibility and help with affordability challenges.',
              'Introduced six-month advance booking, online processing and a generous cancellation policy on product transfers.',
            ],
          },
          {
            boldText: 'Service',
          },
          {
            list: [
              'Launched an improved online broker affordability calculator, as well as updated online product selection tools and guides.',
              'Introduced a one-system portfolio landlord process, prepopulating data to speed up applications, including upgraded automated valuations and day-one offers.',
              'Invested in our business development teams, ensuring every broker has two points of contact with our field and office based business development managers.',
            ],
          },
          {
            boldText: 'Education',
          },
          {
            list: [
              'Educated brokers on the benefits of making sustainability their USP and published CPD-accredited sustainability training. We also launched retrofit guides and regulatory information to encourage landlords to make energy efficiency improvements to their portfolios.',
              'Hosted Moving Forward Together (MFT) educational regional workshops to support and educate the market. We invited third-party speakers focused on adding value, not just policy update sessions, across topics including the wider economy, fraud prevention, sustainability, regulation and technology.',
              `Launched our Landlord Retrofit Tool through brokers, helping you support your clients by suggesting specific ways they can boost their property's energy efficiency.`,
              'Added a grants page and feature to the Landlord Retrofit Tool to flag which grants might be available to landlords or tenants in each nation of the UK.',
            ],
          },
        ],
      },
      {
        sectionID: 'More to come',
        isAccordion: false,
        sectionHeading: 'More to come',
        headingLevel: 'h2',
        sectionBody: [
          {
            boldText:
              "We never take being the UK's biggest buy-to-let lender for granted because we want to remain your number one choice.",
          },
          {
            text: 'Most of all, we want to support more of your landlord clients with their purchase and refinancing goals. Our products and criteria changes over the last 18 months make it possible for landlords to borrow more by getting the numbers to stack up. That helps get the market moving, easing supply and demand pressures, and making lending more accessible.',
          },
          {
            text: "So, we'll continue to adapt and enhance our market-leading service and product offering to support brokers and landlords. And we'll supplement this with education, insights and knowledge sharing.",
          },
          {
            text: "We know brokers have evolved too, and you are constantly adapting your knowledge, processes and approach to keep pace with the changing mortgage landscape and private rented sector. And we're fully aware that you do this so you can best serve your clients.",
          },
          {
            text: "The last few years have been a time of great change for all of us, but it doesn't stop now. Let's keep working on ways to enhance what we offer to landlords, in tough times and better ones, by ensuring we cater for mainstream and underserved segments of the buy-to-let market.",
          },
          {
            text: "And let's all work toward creating a better, fairer, high-quality private rented sector, that works for everyone.",
          },
        ],
      },
    ],
  },
};

export default buyToLetChangedBMSolutionsChangedWithIt;
