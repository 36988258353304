export const existingCustomers = {
    updatingInfoPleaseCallTel: "updating-info-please-call-tel-link",
    convertingCustomerAccountNumbers: {
        accordion: "converting-customer-account-numbers-accordion",
        text: "Sometimes, our system and online forms need account numbers to have 14 digits. Here's a guide on how to convert account numbers into that format."
    },
    ifTheAccountNumberHas12Digits: {
        accordion: "if-the-account-number-has-12-digits-accordion",
        text1: "Remove the forward-slash (/) and the dash (-) and replace them with '00' at the end.",
        text2: "60/02013198-10 converts to 60020131981000."
    },
    ifAccountNumber11DigitsOnly1DigitAfterHash: {
        accordion: "if-account-number-11-digits-only-1-digit-after-hash-accordion",
        text1: "Remove the forward-slash (/), replace the dash (-) with '0' and add '00' at the end.",
        text2: "60/02013198-1 converts to 60020131980100."
    },
    ifAccountNumberHas11Digits2DigitsAfterDash: {
        accordion: "if-account-number-has-11-digits-2-digits-after-dash-accordion",
        text1: "Remove the dash (-), replace the forward-slash (/) with '0' and add '00' at the end.",
        text2: "60/2013198-01 converts to 60020131980100."
    },
    ifAccountNumberHas10Digits: {
        accordion: "if-account-number-has-10-digits-accordion",
        text1: "Replace both the forward-slash (/) and dash (-) with '0' and add '00' at the end.",
        text2: "60/2013198-1 converts to 60020131980100."
    },
    payingCustomerErcs: {
        accordion: "paying-customer-ercs-so-product-transfer-can-be-keyed-accordion",
        link: "paying-customer-ercs-complete-this-form-link",
        text1: "Important to know",
        tel: "important-to-know-servicing-team-tel-link"
    },
    updatingProductTransfersFurtherAdvances: {
        accordion: "updating-product-transfers-further-advances-accordion",
        link: "updating-product-transfers-complete-this-form-link",
        amendmentLink: "schedule-amendment-form-link",
        text1: "If your customer has a buy to let flexible mortgage",
        text2: "You'll need to complete a scheme amendment form if want to submit a product transfer or further advance."
    }
  };
  