import React from 'react';

import { Heading, Paragraph, Grid } from '@constellation/core';
import { StyledLink } from 'client/components/styledLink/StyledLink';

import { Tile } from './NavigationTiles.config';
import {
  FullWidthArticleBox,
  FullWidthBox,
  GridItemStretchChildren,
} from './NavigationTiles.styled';

type Props = {
  navigationTiles: Tile[];
  pageTestId: string;
  layout?: 'grid' | 'list';
  icon?: 'right' | 'left' ;
};

function NavigationTiles({ navigationTiles, pageTestId, layout,icon }: Props) {
  let BoxContainer = FullWidthBox;
  let gridLayout = 4;
  if (layout === 'list') {
    BoxContainer = FullWidthArticleBox;
    gridLayout = 8;
  }
  return (
    <Grid>
      {navigationTiles &&
        navigationTiles.map((tile: Tile, index) => (
          <GridItemStretchChildren md={gridLayout} key={`${tile.linkText}`}>
            <BoxContainer
              key={`${tile.linkText}`}
              data-testid={`${pageTestId}-${index + 1}`}
              marginBottom="05"
            >
              <Heading as="h2" marginBottom="03" >
                <StyledLink
                  to={tile.url}
                  iconPosition={icon}
                  size="s3"
                  underline
                  weight="bold"
                  data-testid={`${tile.linkTestId}-label`}
                  marginRight="04"
                >
                  {tile.linkText}
                </StyledLink>
              </Heading>

              <Paragraph
                marginBottom="none"
                data-testid={`${tile.linkTestId}-text`}
              >
                {tile.boxText}
              </Paragraph>
            </BoxContainer>
          </GridItemStretchChildren>
        ))}
    </Grid>
  );
}

export default NavigationTiles;
