// we need a separate file so that both the server build & client build can share the same content file, attempting to use
// the content files from the client folder will break if they contain anything other than text (eg React components)
import { MetaContent } from './MetaContent';

const home: MetaContent = {
  title: 'Homepage | BM Solutions',
  description:
    'BM Solutions is the Buy to Let brand of Lloyds Banking Group, dedicated to supporting intermediaries and mortgage brokers with award-winning service.',
};

const ourProducts: MetaContent = {
  title: 'Our products | BM Solutions',
  description:
    'Search BM Solutions range of fixed and tracker buy to let mortgages to suit your clients needs with various fixed fee, percentage fee or fee free options',
};

const ourCriteria: MetaContent = {
  title: 'Our criteria | BM Solutions',
  description:
    'BM Solutions criteria page makes it easy for Brokers and Intermediaries to understand our lending criteria for buy to let mortgages',
};

const calculators: MetaContent = {
  title: 'Calculators | BM Solutions',
  description:
    'BM Solutions buy to let rental income mortgage calculators makes it simple for Brokers and Intermediaries to understand whether we can lend to their client',
};

const sustainabilityHub: MetaContent = {
  title: 'Sustainability | BM Solutions',
  description:
    'BM Solutions helps Brokers and Intermediaries understand how to support their buy to let clients with sustainability',
};

const placingBusiness: MetaContent = {
  title: 'Placing business | BM Solutions',
  description:
    'BM Solutions supports Brokers and Intermediaries to place buy to let mortgage business with hints, tips and keying guides',
};

const resources: MetaContent = {
  title: 'Resources | BM Solutions',
  description:
    'BM Solutions provides useful resources to supports Brokers and Intermediaries to place buy to let mortgages business',
};

const contactUs: MetaContent = {
  title: 'Contact us | BM Solutions',
  description:
    'Search for contact details of your BM Solutions BDM to support you in placing buy to let mortgage business',
};

const eligibility: MetaContent = {
  title: 'Eligibility | BM Solutions',
};

const yourBalance: MetaContent = {
  title: 'Your balance | BM Solutions',
};

const termsAndConditions: MetaContent = {
  title: 'Terms & Conditions | BM Solutions',
};

const accessibility: MetaContent = {
  title: 'Accessibility | BM Solutions',
};

const privacy: MetaContent = {
  title: 'Privacy | BM Solutions',
};

const cookies: MetaContent = {
  title: 'Cookies | BM Solutions',
};

const existingCustomers: MetaContent = {
  title: 'Existing customers | Contact us | BM Solutions',
};

const newBusinessEnquiries: MetaContent = {
  title: 'New business enquiries | Contact us | BM Solutions',
};

const complaints: MetaContent = {
  title: 'Complaints | Contact us | BM Solutions',
};

const intermediaryServiceSupport: MetaContent = {
  title: 'Intermediary service support | Contact us | BM Solutions',
};

const bdmSearch: MetaContent = {
  title: 'Search for a BDM | Contact us | BM Solutions',
};

const submittedMortgageApplications: MetaContent = {
  title: 'Submitted mortgage applications | Contact us | BM Solutions',
};

const epcRegulations: MetaContent = {
  title: 'Current and proposed EPC regulations | Sustainability | BM Solutions',
};

const lowerRatesForEnergyEfficientHomes: MetaContent = {
  title:
    'Lower rates for energy efficient homes | Sustainability | BM Solutions',
};

const grantsAndSchemes: MetaContent = {
  title: 'Grants and schemes | Sustainability | BM Solutions',
};

const landlordRetrofitTool: MetaContent = {
  title: 'Landlord Retrofit Tool | Sustainability | BM Solutions',
};

const educationalHub: MetaContent = {
  title: 'Educational hub | Sustainability | BM Solutions',
};

const freeConveyancingService: MetaContent = {
  title: 'Free Conveyancing Service | Contact us | BM Solutions',
};

const epcStepByStepGuide: MetaContent = {
  title: 'EPC step by step guide | Sustainability | BM Solutions',
};

const productTransfers: MetaContent = {
  title: 'Product Transfers | Placing business | BM Solutions',
};

const furtherAdvances: MetaContent = {
  title: 'Further advances | Placing business | BM Solutions',
};

const transferOrPurchaseOfEquity: MetaContent = {
  title: 'Transfer / purchase of equity | Placing business | BM Solutions',
};

const submittingBtlOrLtbMortgageApplication: MetaContent = {
  title:
    'Submitting a new BTL or LTB mortgage application | Placing business | BM Solutions',
};

const porting: MetaContent = {
  title: 'Porting | Placing business | BM Solutions',
};

const fees: MetaContent = {
  title: 'Fees | Placing business | BM Solutions',
};

const documentUpload: MetaContent = {
  title: 'Document upload | Placing business | BM Solutions',
};

const rateChangeCalculator: MetaContent = {
  title: 'Rate change calculator | Calculators | BM Solutions',
};

const security: MetaContent = {
  title: 'Security | BM Solutions',
};

const register: MetaContent = {
  title: 'Register | BM Solutions',
};

const sitemap: MetaContent = {
  title: 'Sitemap | BM Solutions',
};

const articles: MetaContent = {
  title: 'Articles | BM Solutions',
  description:
    'BM Solutions provides useful articles to support Brokers and Intermediaries in placing buy to let mortgages business',
};

const notFound: MetaContent = {
  title: 'Not found | BM Solutions',
};

const articleImprovingQualityBenefitsLandlordsAndTenantsPage: MetaContent = {
  title:
    'Improving the quality of rental homes benefits landlords and tenants | Articles | BM Solutions',
  description:
    'BM Solutions provides a useful article for Brokers and Intermediaries on improving quality in the buy to let mortgages market',
};

const buyToLetChangedBmSolutionsChangedWithItPage: MetaContent = {
  title:
    'Buy-to-let changed BM Solutions changed with it | Articles | BM Solutions',
  description:
    'Buy-to-let market evolution: BM Solutions adapts to ongoing changes, offering innovative solutions and support for landlords.',
};

const articleLetsGetRetrofitForTheFuturePage: MetaContent = {
  title: `Let's get retrofit for the future | Articles | BM Solutions`,
  description: `BM Solutions provides a useful article for Brokers and Intermediaries on Let's get retrofit for the future`,
};

export const MetaContentValues = {
  home,
  calculators,
  sustainabilityHub,
  ourProducts,
  ourCriteria,
  placingBusiness,
  resources,
  contactUs,
  Eligibility: eligibility,
  YourBalance: yourBalance,
  TermsAndConditions: termsAndConditions,
  Accessibility: accessibility,
  Privacy: privacy,
  Cookies: cookies,
  ContactUs: contactUs,
  ExistingCustomers: existingCustomers,
  NewBusinessEnquiries: newBusinessEnquiries,
  Complaints: complaints,
  IntermediaryServiceSupport: intermediaryServiceSupport,
  BDMSearch: bdmSearch,
  SubmittedMortgageApplications: submittedMortgageApplications,
  EpcRegulations: epcRegulations,
  LowerRatesForEnergyEfficientHomes: lowerRatesForEnergyEfficientHomes,
  GrantsAndSchemes: grantsAndSchemes,
  LandlordRetrofitTool: landlordRetrofitTool,
  EducationalHub: educationalHub,
  FreeConveyancingService: freeConveyancingService,
  EpcStepByStepGuide: epcStepByStepGuide,
  ProductTransfers: productTransfers,
  FurtherAdvances: furtherAdvances,
  TransferOrPurchaseOfEquity: transferOrPurchaseOfEquity,
  SubmittingBtlOrLtbMortgageApplication: submittingBtlOrLtbMortgageApplication,
  Porting: porting,
  Fees: fees,
  DocumentUpload: documentUpload,
  RateChangeCalculator: rateChangeCalculator,
  Security: security,
  Register: register,
  Sitemap: sitemap,
  Articles: articles,
  NotFound: notFound,
  ArticleImprovingQualityBenefitsLandlordsAndTenantsPage:
    articleImprovingQualityBenefitsLandlordsAndTenantsPage,
  BuyToLetChangedBmSolutionsChangedWithItPage:
    buyToLetChangedBmSolutionsChangedWithItPage,
  ArticleLetsGetRetrofitForTheFuture: articleLetsGetRetrofitForTheFuturePage,
};
