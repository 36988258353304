import { AppContent } from 'content/AppContent';

import * as routes from '../../routes/manifest';
import { MetaContentValues } from '../MetaContentValues';

const placingBusiness: AppContent['placingBusiness'] = {
  metaContent: MetaContentValues.placingBusiness,

  pageTitle: 'Placing business',
  bannerSubheader:
    "Here's where you can find information and guidance on everything to do with placing business with Birmingham Midshires.",
  bannerSubheader1:
    'The BM Solutions Online application system is available Mon-Fri 8am to 8pm, Sat 8am to 5pm, and closed on Sundays.',
  navigationTiles: [
    {
      linkText: 'Submitting a new BTL or LTB mortgage application',
      boxText:
        'Select this section to understand how to submit a new BTL or LTB application.',
      url: routes.SubmittingBtlOrLtbMortgageApplication,
      linkTestId: 'link-submitting-a-new-btl-or-ltb-mortgage-application',
    },
    {
      linkText: 'Product transfers',
      boxText:
        'When can your customers product transfer and how you do it for them? Find out in our product transfer guide.',
      url: routes.ProductTransfers,
      linkTestId: 'link-product-transfers',
    },
    {
      linkText: 'Further advances',
      boxText:
        'Find out everything you need to know about further advances for your customers.',
      url: routes.FurtherAdvances,
      linkTestId: 'link-further-advances',
    },
    {
      linkText: 'Transfer / purchase of equity',
      boxText: 'Read our in-depth guide to transferring or purchasing equity.',
      url: routes.TransferOrPurchaseOfEquity,
      linkTestId: 'link-transfer-purchase-of-equity',
    },
    {
      linkText: 'Porting',
      boxText:
        'Find the information you need on porting an existing mortgage product.',
      url: routes.Porting,
      linkTestId: 'link-porting',
    },
    {
      linkText: 'Register',
      boxText: 'Understand our registration process.',
      url: routes.Register,
      linkTestId: 'link-registration-guide',
    },
    {
      linkText: 'Fees',
      boxText: "Here's where you'll find all our up-to-date fees.",
      url: routes.Fees,
      linkTestId: 'link-fees',
    },
    {
      linkText: 'Document upload',
      boxText:
        "Our integrated document upload process allows you to securely upload documents to support your customers' applications.",
      url: routes.DocumentUpload,
      linkTestId: 'link-document-upload',
    },
  ],
};

export default placingBusiness;
