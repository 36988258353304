import React from 'react';

import { Paragraph, Strong } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import BM_Solutions_Head from 'assets/common/BM_Solutions_Head.png';
import ArticleView from 'client/components/articleView/articleView/ArticleView';
import GradientBox from 'client/components/gradientBox/GradientBox';

import { ArticleLetsGetRetrofitForTheFutureContent } from './ArticleLetsGetRetrofitForTheFuture.config';

function BoxComponent({ boxContent }) {
  return (
    <GradientBox>
      <Paragraph inverse data-testid="article-gradient-header-one">
        <Strong inverse size="s4">
          {boxContent.paragraphOne}
        </Strong>
      </Paragraph>
      <Paragraph inverse data-testid="article-gradient-header-one">
        <Strong inverse size="s4">
          {boxContent.paragraphTwo}
        </Strong>
      </Paragraph>
      <Paragraph inverse data-testid="article-gradient-text1">
        {boxContent.paragraphThree}
      </Paragraph>
      <Paragraph inverse data-testid="article-gradient-text2">
        {boxContent.paragraphFour}
      </Paragraph>
      <Paragraph inverse data-testid="article-gradient-text3">
        {boxContent.paragraphFive}
      </Paragraph>
    </GradientBox>
  );
}

function ArticleLetsGetRetrofitForTheFuturePage(): React.JSX.Element {
  const articleContent =
    useContent<ArticleLetsGetRetrofitForTheFutureContent>();

  return (
    <ArticleView
      content={articleContent}
      imgSrc={BM_Solutions_Head}
      component={() => BoxComponent(articleContent)}
    />
  );
}

export default ArticleLetsGetRetrofitForTheFuturePage;
