import { Text } from '@constellation/core';
import styled, { StyledComponent } from 'styled-components';

export const StyledHeader = styled.header`
  background-color: ${({ theme }) => theme.header_color_background};
  margin-bottom: ${({ theme }) => theme.spacing_none};
  padding: ${({ theme }) => theme.spacing_04} 0;
`;

export const StyledSafeSecureText: StyledComponent<typeof Text, any> = styled<
  typeof Text
>(Text)`
  ${({ theme }: any) => theme.cssPrefix} {
    display: flex;

    font-size: ${({ theme }) => theme.typography_text_font_size_s1};
    font-weight: ${({ theme }) => theme.typography_font_weight_normal_css};
    line-height: ${({ theme }) => theme.typography_text_line_height_s1_css};
    transition-duration: ${({ theme }: any) => theme.transition_duration_fast};

    @media (min-width: ${({ theme }: any) => theme.breakpoint_sm}) {
      font-size: ${({ theme }) => theme.typography_text_font_size_s2};
      font-weight: ${({ theme }) => theme.typography_font_weight_bold_css};
      line-height: ${({ theme }) => theme.typography_text_line_height_s2_css};
    }
  }
`;

export const StyledInnerContainer = styled.div`
  ${({ theme }: any) => theme.cssPrefix} {
    display: flex;
    justify-content: space-between;
  }
`;

export const StyledBirminghamSolutionsLogo = styled.img`
  ${({ theme }: any) => theme.cssPrefix} {
    width: 7rem;
    height: 1.625rem;
    transition-duration: ${({ theme }: any) => theme.transition_duration_fast};

    @media (min-width: ${({ theme }: any) => theme.breakpoint_sm}) {
      width: 10.5rem;
      height: 2.321rem;
    }

    @media (min-width: ${({ theme }: any) => theme.breakpoint_md}) {
      width: 14rem;
      height: 3.25rem;
    }
  }
`;

export const StyledSignInContainer = styled.div`
  ${({ theme }: any) => theme.cssPrefix} {
    align-items: center;
    background: 'none';
    border: none;
    display: flex;
    justify-content: space-between;
    padding: 0 ${({ theme }: any) => theme.spacing_03};
  }
`;

export const StyledSignInIconContainer = styled.div`
  ${({ theme }: any) => theme.cssPrefix} {
    align-items: center;
    display: flex;
    margin-right: ${({ theme }: any) => theme.spacing_02};
  }
`;

export const StyledSignInText: StyledComponent<typeof Text, any> = styled<
  typeof Text
>(Text)`
  ${({ theme }: any) => theme.cssPrefix} {
    display: flex;

    font-size: ${({ theme }) => theme.typography_text_font_size_s1};
    line-height: ${({ theme }) => theme.typography_text_line_height_s1_css};
    transition-duration: ${({ theme }: any) => theme.transition_duration_fast};
    text-decoration: underline;

    @media (min-width: ${({ theme }: any) => theme.breakpoint_sm}) {
      font-size: ${({ theme }) => theme.typography_text_font_size_s2};
      line-height: ${({ theme }) => theme.typography_text_line_height_s2_css};
    }
  }
`;
