import React from 'react';

import { Paragraph, Strong } from '@constellation/core';
import { InfoPanelContent } from 'client/components/infoPanel/InfoPanel.config';

import { anchorLink, anchors } from './anchors';
import * as routes from '../../../routes/manifest';

const existingCustomer: {
  title: string;
  id: string;
  infoPanel: InfoPanelContent;
} = {
  title: 'Existing customer',
  id: anchors.existingCustomer.id,
  infoPanel: {
    contentSections: [
      {
        sectionHeading: 'Arrears',
        sectionID: anchors.existingCustomer.arrears,
        isAccordion: true,
        sectionBody: [
          {
            text: "We can't accept further advance applications if a mortgage has been at least one month in arrears within the last three months.",
          },
          {
            text: "You'll need to advise the customer to bring their account up-to-date with no arrears for at least three months before re-applying.",
          },
          {
            text: (
              <>
                Standalone product transfers can be processed as soon as the
                account is up to date without the need to wait three months.
              </>
            ),
          },
        ],
      },
      {
        sectionHeading: 'Early repayment charges',
        sectionID: anchors.existingCustomer.earlyRepaymentCharges,
        isAccordion: true,
        sectionBody: [
          {
            text: (
              <>
                Some of our products have early repayment charges (ERCs). These
                will apply if your customer repays some or all of their mortgage
                in the ERC period.
              </>
            ),
          },
          {
            text: (
              <>
                For mortgage products with ERCs, up to 10% of the amount
                outstanding can be repaid without having to pay an ERC. We can
                change or withdraw this at any time.
              </>
            ),
          },
          {
            text: (
              <>
                On a porting application, any ERC, which might apply will be
                waived or refunded if the ERC period ends in the next three
                months.
              </>
            ),
          },
          {
            text: (
              <>
                On a product transfer application, any ERC which might apply
                will be waived or refunded if the ERC period ends in the next
                four months.
              </>
            ),
          },
          {
            text: "You can't start a product transfer application more than four months before the ERC period ends.",
          },
          {
            text: (
              <>
                If the account has multiple sub-accounts with different ERC
                periods, you can only complete a product transfer application on
                the sub-account with the ERC expiring the soonest.
              </>
            ),
          },
          {
            text: "Once the application has been submitted and the new product has started, you'll be able to apply for a product transfer on the other sub-accounts. Instead, you can wait until the ERC on all sub-accounts have three months left until expiry and apply for a product transfer application on all eligible sub-accounts.",
          },
          {
            text: (
              <>
                For product transfers and further advances, ERCs will be charged
                on any overpayments made over the 10%, even if the customer is
                within four months of the ERC expiry.
              </>
            ),
          },
        ],
      },
      {
        sectionHeading: 'Further advances',
        sectionID: anchors.existingCustomer.furtherAdvances,
        isAccordion: true,
        sectionBody: [
          {
            text: (
              <>
                The minimum further advance amount is <Strong>£5,000.</Strong>
              </>
            ),
          },
          {
            text: (
              <>
                When applying for a further advance, the product chosen must be
                from the same scheme as the main borrowing - BTL or LTB.
              </>
            ),
          },
          {
            text: 'We do not accept further advance applications where the EPC rating is F, G or an exemption is in place, except when the purpose of loan is for BTL repairs.',
          },
          {
            text: "Further advances can't be taken within 6 months of completion of the original mortgage.",
          },
          {
            text: (
              <>
                A further advance must have completed at least 12 months ago
                before a new further advance application can be taken.
              </>
            ),
          },
          {
            text: <Strong>Arrears</Strong>,
          },
          {
            text: "If the customer hasn't made their normal monthly payment and is in arrears, they can't apply for a further advance until the arrears have been cleared. Their payments must also be made in full, on time for three consecutive months.",
          },
          {
            text: "A further advance and a product transfer can't be applied for at the same time. If a product transfer application has been applied for, you'll need to wait until the new product has started before the further advance application can be submitted.",
          },
          {
            text: "If a product transfer application is submitted and subsequently cancelled, you'll need to wait until the 1st of the following month before applying for a further advance application.",
          },
          {
            text: <Strong>Acceptable reasons for a further advance</Strong>,
          },
          {
            container: ({ children }) => <Paragraph>{children}</Paragraph>,
            link: {
              displayText: 'Remortgage & Capital raising',
              href: anchorLink(anchors.lending.remortgageAndCapitalRaising),
              external: false,
            },
          },
          {
            text: <Strong>Maximum LTV</Strong>,
          },
          {
            text: 'The total lending on an individual property including new further advance',
          },
          {
            list: [
              '65% LTV between £1,000,000 and £2,000,000',
              '75% LTV up to £1,000,000',
              '80% LTV available for lending up to £350,000, including all fees, on EPC rated properties A-C',
            ],
          },
          {
            text: (
              <>
                Where the LTV is over 80% at application based on the current
                index valuation, a revaluation and revaluation fee will be
                needed. Lending will be subject to the value and rental figure
                following the revaluation.
              </>
            ),
          },
          {
            container: ({ children }) => <Paragraph>{children}</Paragraph>,
            link: {
              href: routes.Fees,
              displayText: 'View our current revaluation fees.',
              external: false,
            },
          },
        ],
      },
      {
        sectionHeading: 'Overpayments',
        sectionID: anchors.existingCustomer.overpayments,
        isAccordion: true,
        sectionBody: [
          {
            container: ({ children }) => <Paragraph>{children}</Paragraph>,
            link: {
              displayText:
                'Making Overpayments | Knowledge Base | Birmingham Midshires',
              href: 'https://www.bmmortgages.co.uk/knowledge-base/making-overpayments',
              external: true,
            },
          },
          {
            textWithLinks: [
              'One-off or regular overpayments can be made at any time. If early repayment charges (ERCs) apply to the product, please refer to our ',
              {
                displayText: 'ERC section',
                href: anchorLink(
                  anchors.existingCustomer.earlyRepaymentCharges,
                ),
                external: false,
              },
              ' for more details.',
            ],
          },
        ],
      },
      {
        sectionHeading: 'Payment holidays',
        sectionID: anchors.existingCustomer.paymentHolidays,
        isAccordion: true,
        sectionBody: [
          {
            text: "Payment holidays aren't allowed.",
          },
        ],
      },
      {
        sectionHeading: 'Portability',
        sectionID: anchors.existingCustomer.portability,
        isAccordion: true,
        sectionBody: [
          {
            text: "Any early repayment charges (ERCs) won't have to be paid on existing BTL mortgage products ported to a new application.",
          },
          {
            text: "If a product is portable, it will be outlined in the original mortgage offer and supporting documentation. You can't port a product to a remortgage application if the property is currently mortgaged. You can port to a remortgage of an unencumbered (mortgage free) property the customer already owns or a new purchase.",
          },
          {
            text: "Porting to an unencumbered remortgage can only be done using the 'Remortgage own conveyancer' option. You can't select our free legal service.",
          },
          {
            text: (
              <>
                Where a new application is submitted before redemption of the
                current mortgage, the product can be ported.
              </>
            ),
          },
          {
            text: (
              <Strong>
                An ERC can also be waived/refunded without porting if:
              </Strong>
            ),
          },
          {
            list: [
              'a new mortgage is being taken in the last three months of the ERC period of the current product',
              'you apply for a new application on a new product within three months of an existing BM BTL mortgage being redeemed.',
            ],
          },
          {
            textWithLinks: [
              'For more information, please refer to our ',
              {
                displayText: 'porting guide.',
                href: routes.Porting,
                external: false,
              },
            ],
          },
        ],
      },
      {
        sectionHeading: 'Product transfers',
        sectionID: anchors.existingCustomer.productTransfers,
        isAccordion: true,
        sectionBody: [
          {
            text: "Product transfers are available for non-regulated buy to let and let to buy customers only. Regulated mortgages, including residential customers and regulated buy to let, such as family buy to let aren't eligible for product transfers.",
          },
          {
            text: "Once a product transfer's been submitted, an automated eligibility check will be carried out to make sure your customers qualify. If your customers don't pass the eligibility check, you'll see an on-screen message explaining the reason why and what you and/or your customers should do next.",
          },
          {
            text: (
              <Strong>
                Reasons why customers might fail the eligibility check include:{' '}
              </Strong>
            ),
          },
          {
            list: [
              'The account is a regulated mortgage.',
              'The account is in arrears.',
              'The account has multiple Direct Debits.',
              'The account has a payment arrangement in place.',
              'The tenure of the property information is missing from the account.',
              'No sub-accounts are available to transfer.',
              'The account requires a review of the sub-account balances. ',
            ],
          },
        ],
      },
      {
        sectionHeading: 'Product transfer timescales',
        sectionID: anchors.existingCustomer.productTransferTimescales,
        isAccordion: true,
        sectionBody: [
          {
            text: 'Product transfers can be submitted at any time in the month. The earliest a product transfer can start is the 1st of the following month.',
          },
          {
            text: "If your customer is within the last three months of an existing product, you can choose to start the new product early. If you select this option, we'll waive any early repayment charge (ERC). You can also choose to forward date the new product to start after the current product has ended.",
          },
          {
            text: 'If the account has multiple sub-accounts with different ERC periods, you can only complete a product transfer application on the sub-account with the ERC expiring the soonest.',
          },
          {
            text: 'When your customer is within the last 4 months of an existing product, you can choose to select a new product and forward date the new product to start after the current product has ended.',
          },
          {
            boldText: 'Example keying dates',
          },
          {
            basicTable: {
              ariaLabel: 'Table of example keying dates for product transfers',
              testIdPrefix: 'product-transfer-keying-dates-table-test-id',
              width: 'auto',
              responsive: true,
              columns: [
                { displayText: 'Product expiry', key: 'product-expiry' },
                { displayText: 'Keying from', key: 'keying-from' },
                {
                  displayText: 'Options for start date',
                  key: 'options-for-start-date',
                },
              ],
              rows: [
                {
                  'product-expiry': '28 February',
                  'keying-from': '01 November',
                  'options-for-start-date': '01 March',
                },
                {
                  'product-expiry': '',
                  'keying-from': '01 December',
                  'options-for-start-date': '01 January or 01 March',
                },
                {
                  'product-expiry': '',
                  'keying-from': '01 January',
                  'options-for-start-date': '01 February or 01 March',
                },
                {
                  'product-expiry': '',
                  'keying-from': '01 February',
                  'options-for-start-date': '01 March',
                },
                {
                  'product-expiry': '31 March',
                  'keying-from': '01 December',
                  'options-for-start-date': '01 April',
                },
                {
                  'product-expiry': '',
                  'keying-from': '01 January',
                  'options-for-start-date': '01 February or 01 April',
                },
                {
                  'product-expiry': '',
                  'keying-from': '01 February',
                  'options-for-start-date': '01 March or 01 April',
                },
                {
                  'product-expiry': '',
                  'keying-from': '01 March',
                  'options-for-start-date': '01 April',
                },
              ],
            },
          },
        ],
      },
      {
        sectionHeading: 'Regulated mortgages',
        sectionID: anchors.existingCustomer.regulatedMortgages,
        isAccordion: true,
        sectionBody: [
          {
            text: (
              <>
                We no longer accept any changes to existing regulated mortgages
                held with us.
              </>
            ),
          },
          {
            text: <Strong>This includes: </Strong>,
          },
          {
            list: [
              'Residential.',
              'Buy to let, if let to a family member.',
              'Self-build.',
              'Self-certified.',
              'Sub-prime.',
              'Mortgage plus.',
            ],
          },
          {
            text: "We can't offer any contractual changes to existing mortgages including further advances, product transfers, transfer of equity or repayment term/type changes. If the original mortgage is regulated, the status of the mortgage will never change.",
          },
          {
            text: (
              <Strong>
                {`You have the following options if you have a customer who'd like
                to make a change to their regulated account with us:`}
              </Strong>
            ),
          },
          {
            list: [
              'The customer can contact our Specialist Remortgage team on 0345 608 0263.',
              'You can review the whole of market for the customer to remortgage to another lender.',
            ],
          },
        ],
      },
      {
        sectionHeading: 'Transfer/purchase of equity',
        sectionID: anchors.existingCustomer.transferPurchaseOfEquity,
        isAccordion: true,
        sectionBody: [
          {
            textWithLinks: [
              'Please see our ',
              {
                displayText: 'Transfer/purchase of equity guide.',
                href: routes.TransferOrPurchaseOfEquity,
                external: false,
              },
            ],
          },
        ],
      },
    ],
  },
};

export default existingCustomer;
