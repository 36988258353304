import React from 'react';

import { Heading, Main, Paragraph, Strong } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import main_image from 'assets/common/BM_illustration_signpost_white.png';
import GradientBox from 'client/components/gradientBox/GradientBox';
import MetaTags from 'client/components/metaTags/MetaTags';
import { NavigationTiles } from 'client/components/navigationTiles';
import RedGradientBox from 'client/components/redGradientBox/RedGradientBox';
import testIds from 'client/testIds';

import { ContactUsPageContent } from './ContactUs.config';

export default function ContactUsPage() {
  const { pageTitle, pageText, navigationTiles, metaContent } =
    useContent<ContactUsPageContent>();
  return (
    <Main>
      <MetaTags metaContent={metaContent} />

      <GradientBox>
        <RedGradientBox
          imgSrc={main_image}
          imgAlt="Icon of a signpost"
        >
          <Heading as="h1" size="s7" data-testid={testIds.contactUsPage.heading} marginBottom="03" inverse>
            {pageTitle}
          </Heading>
          <Paragraph data-testid={testIds.contactUsPage.text}>
            <Strong inverse>
              {pageText}
            </Strong>
          </Paragraph>
        </RedGradientBox>
      </GradientBox>

      <NavigationTiles
        navigationTiles={navigationTiles}
        pageTestId={testIds.contactUsPage.sectionTitle}
        icon="right"
      />
    </Main>
  );
}
