import React from 'react';

import { Link } from '@constellation/core';
import { contentSectionBox } from 'client/components/infoPanel/InfoPanel.config';

import { anchorLink, anchors } from './criteria/anchors';
import existingCustomer from './criteria/existingCustomer';
import lending from './criteria/lending';
import property from './criteria/property';
import topSlicing from './criteria/topSlicing';
import { AppContent } from '../AppContent';
import { MetaContentValues } from '../MetaContentValues';

const ourCriteria: AppContent['ourCriteria'] = {
  metaContent: MetaContentValues.ourCriteria,
  pageTitle: 'Our criteria',
  heading: 'Our criteria',
  introText:
    "Here's an overview of our eligibility criteria for our buy to let and let to buy mortgages. We've broken it down into easy-to-read sections for you. Just select them individually to find out what you need to know.",
  jumpToASection: {
    id: anchors.jumpToASection.id,
    title: 'Jump to a section',
    links: [
      {
        label: 'Application',
        url: anchors.application.id,
      },
      {
        label: 'Customer',
        url: anchors.customer.id,
      },
      {
        label: 'Income',
        url: anchors.income.id,
      },
      {
        label: 'Lending',
        url: anchors.lending.id,
      },
      {
        label: 'Property',
        url: anchors.property.id,
      },
      {
        label: 'Existing customer',
        url: anchors.existingCustomer.id,
      },
      {
        label: 'Top slicing',
        url: anchors.topSlicing.id,
      },
    ],
  },

  application: {
    title: 'Application',
    id: anchors.application.id,
    infoPanel: {
      contentSections: [
        {
          sectionHeading: 'Applicants',
          sectionID: anchors.application.applicants,
          isAccordion: true,
          sectionBody: [
            {
              text: 'The maximum number of applicants allowed for each application is four.',
            },
            {
              text: 'If you need to change the order of applicants or add/remove an applicant, please follow these instructions. This will make sure the right data is allocated to each applicant.  ',
            },
            {
              boldText: 'To change the applicant order:',
            },
            {
              list: [
                'Go to the applicant summary table at the bottom of the personal details screen.',
                'Use the ↓ and ↑ arrows on the left-hand side to amend the order.',
                "Select the 'Submit' button when you're done.",
              ],
            },
            {
              boldText: 'To remove an applicant:',
            },
            {
              list: [
                'Go to the applicant summary table at the bottom of the personal details screen.',
                "Select the 'Delete' button on the right-hand side on the applicant you want to remove.",
                "Select the 'Submit' button when you're done.",
              ],
            },
            {
              boldText: 'To add an applicant:',
            },
            {
              list: [
                "Select the 'Add another applicant' button on the personal details screen.",
                'Complete the new applicant details.',
                "Select the 'Save applicant' button.",
                "Select the 'Submit' button when you're done.",
              ],
            },
            {
              text: 'You must not change any of the information in the name, date of birth, or other personal details sections from one applicant to another.',
            },
            {
              text: 'If you have any questions, please speak to your Business Development Manager before making any changes to an application.',
            },
            {
              boldText: 'More information',
            },
            {
              text: (
                <Link
                  href={anchorLink(
                    anchors.lending.firstTimeBuyerFirstTimeLandlord,
                  )}
                >
                  First-time buyer/first-time landlord
                </Link>
              ),
            },
          ],
        },
        {
          sectionHeading: 'Decision in principle',
          sectionID: anchors.application.decisionInPrinciple,
          isAccordion: true,
          sectionBody: [
            {
              text: 'Our Decision in Principle provides a credit decision using minimal information. It states how much we can lend and is valid for three months, subject to a valuation and the information supplied being correct.',
            },
          ],
        },
        {
          sectionHeading: 'Direct Debit details',
          sectionID: anchors.application.directDebitDetails,
          isAccordion: true,
          sectionBody: [
            {
              text: "We will need the details of the personal current account where the mortgage will be paid from at application stage. Business current accounts can't be used.",
            },
            {
              text: "You don't need to complete the Direct Debit mandate form, but we need the account number and sort code to issue a mortgage offer.",
            },
          ],
        },
        {
          sectionHeading: 'Documentation needed',
          sectionID: anchors.application.documentationNeeded,
          isAccordion: true,
          sectionBody: [
            {
              text: 'You must obtain proof of income, deposit, gross monthly rent, and identification from everyone named on the mortgage before keying the application.',
            },
            {
              text: "You can find detailed guides on what documents can and can't be accepted in the relevant sections on this page.",
            },
          ],
        },
        {
          sectionHeading: "Finders' fees",
          sectionID: anchors.application.findersFee,
          isAccordion: true,
          sectionBody: [
            {
              text: "Any application involving a fee paid to a third party, such as an investment club, for finding or introducing a buyer aren't acceptable.",
            },
            {
              text: "This doesn't include the normal fee or commission payable to an estate agent handling the sale.",
            },
          ],
        },
        {
          sectionHeading: 'First monthly payment',
          sectionID: anchors.application.firstMonthlyPayment,
          isAccordion: true,
          sectionBody: [
            {
              text: 'The first monthly payment is always collected in the month following completion of the mortgage. For example, if a mortgage completes in June, the first payment will be collected in July.',
            },
            {
              text: "The payment will normally be collected on the customer's chosen monthly payment date. However, if their mortgage completes in the last few days of the month and there are fewer than four working days until the customer's chosen payment date, the first payment will be collected on 10th of that month.",
            },
            {
              text: "This date will then revert to the customer's chosen payment date on the following month.",
            },
            {
              text: "The first monthly payment includes an amount of 'initial interest' from the day of completion to the end of that month, plus the first monthly mortgage payment. For example, if completion is on the 15th, interest is calculated from the 15th to the end of the month and added to the first payment.",
            },
            {
              text: "We'll write to all customers to tell them when we'll collect their monthly payments and let them know their first payment might be different to their normal monthly payments.",
            },
          ],
        },
        {
          sectionHeading: 'Free conveyancing',
          sectionID: anchors.application.freeConveyancing,
          isAccordion: true,
          sectionBody: [
            {
              text: "Free conveyancing for remortgaging to us is available on most of our remortgage products. Where your customer uses our remortgage service, we'll pay their conveyancer's legal fees and any registration fees.",
            },
            {
              text: 'Your customer will have to pay any fees their existing lender might charge for repaying their current mortgage.',
            },
            {
              text: "These include your customers' existing lender's conveyancer's fees if the existing lender doesn't allow our conveyancer to act for them too.",
            },
            {
              text: "If a customer chooses a product without free conveyancing or uses their own conveyancer, the conveyancer must be on our panel. Your customer must pay any legal charges, search fees, and registration fees or other costs even if they don't go ahead with the remortgage. The insurance search won't be available.",
            },
            {
              boldText:
                'There might also be other costs to consider, including:',
            },
            {
              list: [
                'Transfer of equity.',
                'Purchasing an additional tranche.',
                'Additional TTs where a cashback is involved.',
                'Second charges.',
                'Possessory title.',
                'Unregistered land.',
                'Leasehold properties.',
              ],
            },
            {
              text: 'The remortgage service is also available for customers raising capital on a mortgage-free property.',
            },
            {
              boldText: 'Northern Irish customers',
            },
            {
              text: "Northern Irish law means solicitors appointed by the lender can't provide any additional services to the customer. So, if a customer needs any additional services, they'll need to instruct another solicitor.",
            },
            {
              boldText: 'Scottish Properties - closure of Sasine register',
            },
            {
              text: 'The General Register of Sasine closed to standard securities on 01 April 2016. This change only affects remortgaging of Scottish properties on the old Sasine register.',
            },
            {
              text: "As part of our commitment to make things easier, we'll cover the standard costs for transferring from the Sasine register to the Land Registry during the conveyancing part of our remortgage service, wherever possible.",
            },
            {
              text: "We won't cover all transactions as part of this service if we think they're too complex. For these complex cases, the conveyancer should discuss the additional fees with the customer.",
            },
            {
              boldText: "Complex transactions include, but aren't limited to:",
            },
            {
              list: [
                'Plans report shows either an overlap or shortfall between the legal and occupational extent.',
                'The title is complex with split-off writs, exceptions, and additions that need a full examination to draft the security and obtain a relevant deed plan.',
                'The property is a flat or part of a tenement block with common parts where obtaining neighbouring titles would be needed.',
                'Rural properties where copies of neighbouring titles are needed to check servitudes/access rights etc.',
              ],
            },
            {
              text: "These are high-level examples considered by conveyancer panel firms as the most likely to happen but aren't the only examples. The specific costs associated with complex transactions will vary. Panel firms will apply an hourly rate and agree everything with customers before starting any complex transaction.",
            },
          ],
        },
        {
          sectionHeading: 'Proof of residential purchase - let to buy',
          sectionID: anchors.application.proofOfResidentialPurchaseLetToBuy,
          isAccordion: true,
          sectionBody: [
            {
              text: "All let to buy applications must involve a related residential purchase. We'll only accept applications where customers are intending to rent out their current residential home, so they can buy another residential home.",
            },
            {
              text: 'If the customer is taking a new residential mortgage when purchasing a new home we will need to see a copy of the new residential offer before we can issue the LTB offer.',
            },
            {
              text: "If the customers are buying a new property without a mortgage or the new residential lender is unable to issue the offer we'll need a letter addressed to BM Solutions from the conveyancer dated within three months of the application to confirm:",
            },
            {
              container: contentSectionBox({ marginBottom: '05' }),
              list: [
                'the future residential address',
                'the purchase price',
                "if the property will or won't have a mortgage.",
              ],
            },
            {
              text: "We can't issue a let to buy offer until we've received everything we need.",
            },
          ],
        },
      ],
    },
  },

  customer: {
    title: 'Customer',
    id: anchors.customer.id,
    accordion1: {
      sectionID: anchors.customer.idAddressVerification,
      title: 'ID & address verification',
      id: anchors.customer.idAddressVerification,
      textItems: [
        {
          text: 'All customers must be fully and properly identified as part of the mortgage application process.',
        },
        {
          text: 'Accurate information must be recorded on our application system, including confirmation of whether this is a face-to-face or non-face-to-face application.',
        },
        {
          text: "If the customer doesn't pass our electronic verification checks, you'll need to certify and retain evidence of the customer's identification documents.",
        },
        {
          text: "The documents you can accept depend on whether you've met with the customer in person, or remotely by telephone or video.",
        },
        {
          text: "Here's a guide to understanding what documents you'll need.",
        },
      ],
      boldText: 'Single ID&V',
      text: 'For face-to-face verification, where the customer is physically present and documentation is taken and verified, one document from the single identification list can be used to verify the customer.',
      text2:
        "Single ID can't be used if the customer isn't present when ID&V is collected. This includes video interviews. In this case, use the standard ID&V process.",
      boldText2: 'Single ID&V documents:',
      list: [
        "UK photo card driving licence, showing the customer's current address.",
        'UK passport.',
        'Some EU/EEA identity cards.',
        'Some non-UK passports.',
      ],
      text3:
        'These documents can be accepted in a former name with evidence of the name change, such as a marriage certificate, Deed Poll or Decree Absolute.  ',
      boldText3: 'Standard ID&V',
      text4:
        "Standard ID&V must be followed when the customer isn't present when ID&V is obtained, or the customer doesn't have a single ID document.",
      text5:
        'The customer will need to produce two separate documents. These must be originals and can only be used once in the process for either address verification or identification.  ',
      boldText4:
        "FCA registered firms must make sure any documentation relied upon to verify a customer's identify is copied, certified, and retained in accordance with money laundering regulations. We reserve the right to contact you and request copies of identity documents at any time during the life of the mortgage and for up to five years after cessation.",
      table: {
        ariaLabel:
          'Table for customer identification and verification documents',
        testIdPrefix: 'customer-id-v-table',
        width: 'auto',
        responsive: true,
        columns: [
          { displayText: 'Standard ID&V Documents', key: 'col-stdidv' },
          { displayText: 'Identification', key: 'col-id' },
          { displayText: 'Address', key: 'col-addr' },
        ],
        rows: [
          { 'col-stdidv': 'UK passport', 'col-id': 'Yes', 'col-addr': 'No' },
          {
            'col-stdidv':
              "UK photocard driving licence displaying the customer's current address",
            'col-id': 'Yes',
            'col-addr': 'Yes',
          },
          {
            'col-stdidv':
              'UK paper full driving licence (issued up to 30th June 1998)',
            'col-id': 'Yes',
            'col-addr': 'Yes',
          },
          {
            'col-stdidv': 'Non-UK passports	',
            'col-id': 'Yes',
            'col-addr': 'No',
          },
          {
            'col-stdidv': 'EU/EEA identity cards	',
            'col-id': 'Yes',
            'col-addr': 'No',
          },
          {
            'col-stdidv': 'Biometric residence permit',
            'col-id': 'Yes',
            'col-addr': 'No',
          },
          {
            'col-stdidv': 'EU/EEA photo card driving licence',
            'col-id': 'Yes',
            'col-addr': 'Yes',
          },
          {
            'col-stdidv':
              'UK Benefits/State Pension notification letter - dated within last 12 months',
            'col-id': 'Yes',
            'col-addr': 'Yes',
          },
          {
            'col-stdidv':
              'HMRC correspondence (excluding P45/P60) - must show Tax reference or NI number dated within last 12 months',
            'col-id': 'Yes',
            'col-addr': 'Yes',
          },
          {
            'col-stdidv':
              'Bank, building society or credit union statement dated within last 6 months and issued by a regulated financial sector firm in the UK',
            'col-id': 'No',
            'col-addr': 'Yes',
          },
          {
            'col-stdidv':
              'Mortgage statement from a bank or building society dated within last 12 months and issued by a regulated financial sector firm in the UK',
            'col-id': 'No',
            'col-addr': 'Yes',
          },
          {
            'col-stdidv':
              'Council Tax correspondence (excluding arrears and reminders) - Dated within last 12 months',
            'col-id': 'No',
            'col-addr': 'Yes',
          },
          {
            'col-stdidv': 'Utility bills dated within last 6 months',
            'col-id': 'No',
            'col-addr': 'Yes',
          },
          {
            'col-stdidv':
              "Solicitor's correspondence relating to house purchase and dated within last 3 months",
            'col-id': 'No',
            'col-addr': 'Yes',
          },
        ],
      },
    },
    accordion2: {
      title: 'Expatriates',
      id: anchors.customer.expatriates,
      text: "We don't accept applications from Expatriates.",
    },
    accordion3: {
      title: 'Foreign nationals',
      id: anchors.customer.foreignNationals,
      textItems: [
        {
          text: "Non-UK and non-Irish nationals with less than five years' continuous UK residence must provide evidence of permanent right to reside.",
        },
        {
          text: "We'll accept indefinite leave to remain/Right of Abode, UK Residency stamps in non-UK passports, biometric residence permits, and Home Office documentation confirming indefinite leave to remain in the UK.",
        },
        {
          text: "For EEA/Swiss nationals with Settled or Pre settled status, the 'Share code' can used as residence.",
        },
      ],
      link: {
        label: 'Refugees',
        url: anchorLink(anchors.customer.refugees),
      },
    },
    accordion4: {
      title: 'How to certify a document',
      id: anchors.customer.howToCertifyADocument,
      textItems: [
        {
          text: 'Only identification and address verification and right to reside documents need to be certified.',
        },
        {
          text: "You must certify the documents when they're collected and keep copies on file. You don't need to upload copies to the application unless we ask you to.",
        },
      ],
      title2: 'Certifying documents',
      text1:
        'Documents must be certified by an FCA-registered mortgage intermediary. Certification should include:',
      list: [
        "Certifier's signature, name, and job title.",
        'The date of certification, which must be within three months of the application date.',
      ],
      textItems2: [
        {
          text: 'The certifier must also add written confirmation on the document. Suggested wording for this is:',
        },
        {
          text: "'I certify this is a true and correct copy of the original document as sighted by me'. You can use your own variation of this if you prefer.",
        },
        {
          text: "Separate 'certificates' are acceptable. These are sometimes called ID verification header sheets, broker/adviser verification checklists or similar.",
        },
      ],
      list2: [
        'Details on the certificate must match those of the ID&V documents provided.',
        'Wording to confirm true and correct copies of documents should be included.',
        'The intermediary name and signature must be included on the certificate/document and the date must be within three months of the application date.',
      ],
    },
    accordion5: {
      title: 'Refugees',
      id: anchors.customer.refugees,
      text: "Refugees and asylum seekers must have indefinite leave to remain. This will usually be given in a letter confirming they've been granted 'Indefinite Leave to Remain' from the Immigration and Nationality Directorate.",
      title2: "We can't accept these documents for mortgage purposes:",
      list: [
        'Exceptional leave to remain in the UK.',
        'Humanitarian protection.',
        'Discretionary leave.',
      ],
    },
  },

  income: {
    title: 'Income',
    sectionID: anchors.income.id,
    affordabilityAndSustainability: {
      id: anchors.income.affordabilityAndSustainability,
      title: 'Affordability and sustainability',
      textItems1: [
        {
          text: "Affordability is assessed using the anticipated rental income from the property. We'll also assess personal income when making a lending decision, so it's important the customer's income is keyed accurately as it might affect the decision.",
        },
        {
          text: "When doing this, we expect to see the customer can afford their personal commitments, so we can be confident our lending decision is sustainable if the tenant stops paying or the property needs any maintenance or repairs. An application might be declined if our assessment shows there's not enough disposable income to support the borrowing and the customer's personal commitments.",
        },
        {
          text: 'In our rental cover calculation, we want the customer to have more rent coming in than the normal mortgage payment, so the situations mentioned earlier can be covered.',
        },
        {
          text: "We'll also take the additional cost of increased tax liability for some customers into account.",
        },
        {
          text: 'We understand personal income might not fully reflect the overall financial picture for the applicant. They could have other assets and savings, for example. We also know the buy to let might be in a single name, but personal financial commitments could be held jointly with someone else.',
        },
      ],
      boldText:
        'Some of the information we might need to manually underwrite an application, includes:',
      list: [
        'How the customer manages their commitments.',
        "How they've built up any reserves, including the deposit.",
        'Whether they have previous experience of buying to let',
        'The rationale for sole applications, where finances are joint.',
        'Business details for self-employed customers.',
      ],
      learnMoreAboutTitle: 'Learn more about:',
      taxLink: {
        label: 'Tax on rental income',
        href: anchorLink(anchors.income.taxOnRentalIncome),
      },
      incomeTypesLink: {
        label: 'Income types',
        href: anchorLink(anchors.income.incomeTypes),
      },
    },
    applicantsNotPaidInSterling: {
      id: anchors.income.applicantsNotPaidInSterling,
      title: 'Applicants not paid in sterling',
      textItems1: [
        {
          text: 'Foreign currency income is any income other than sterling received by a customer. Foreign currency income paid into a UK bank account and transferred to sterling is still considered to be foreign currency.',
        },
        {
          text: "Foreign currency income won't be accepted for new mortgage or remortgage applications for new and existing customers, including porting, transfers of mortgaged property (TOMP), and further advances.",
        },
        {
          text: 'The only exceptions to this are further advance applications for essential repairs to the mortgaged property and product transfers.',
        },
      ],
    },
    contractors: {
      id: anchors.income.contractors,
      title: 'Contractors',
      textItems1: [
        {
          text: "If your customer is classed as employed, they'll need to have been in their current role for at least three months.",
        },
        {
          text: "If your customer is classed as self-employed, we'll need at least two years' worth of trading figures.",
        },
      ],

      boldText:
        'Sub-contractors, fixed/short term contracts, and agency workers',
      text: "We'll consider applications from these customers if they have continuous employment of 12 months or more with six months of the contract remaining, or the customer has two years' continuous service in the same type of employment. Evidence of their employment record might be needed.",
      boldText2: 'IT and other contractors',
      text1:
        'For IT contractors on any income and other contractors whose income is more than £500 per day or £75,000 per annum, whether employed or self-employed, we accept the gross value of the contract as evidence of income. All applicants must have a current continuous employment of 12 months or more with six months of the contract remaining or two years continuous service in the same type of employment.',
      text2:
        "The customer should be treated as self-employed if they have more than one contract, or where they've set up a limited company and employ other contractors.",
      boldText3: 'Construction Industry Scheme Contractors (CIS)',
      text4:
        "For these customers, they'll need to provide an average of their latest 3 months income. They should be treated as self-employed if they pay their own tax or sub-contract to more than one company.",

      boldText4: 'Zero-hours contracts',
      text5:
        "Applications from customers employed on a zero-hours contract will be considered. We'll only take income from the total of the last 12 calendar months into account income. Applicants will have to verify their income.",
      boldText5: 'Professional sports people',
      text6:
        "Professional sports people will have to confirm they've either been in continuous employment for 12 months or more with six months of the contract remaining, or two years' continuous service as at the date of application in the same type of employment.",
      text7:
        "It's essential to verify these customers will be able to meet their monthly repayments as they near the end of their career or if their career's ended early because of injury.",
      additionalInfo: 'Additional information:',
      links: [
        {
          label: 'Length of employment/self-employment',
          url: anchorLink(anchors.income.lengthOfEmployment),
        },
        {
          label: 'Income types',
          url: anchorLink(anchors.income.incomeTypes),
        },
        {
          label: 'Self-employed applicants',
          url: anchorLink(anchors.income.selfEmployed),
        },
      ],
    },
    incomeTypes: {
      id: anchors.income.incomeTypes,
      title: 'Income types',
      text1:
        "For all applications, at least one of the customers' needs to have an earned income and/or pension income. All income sources must be recorded accurately. This includes all full years of self-employed income, PAYE, pension income, and all gross monthly rental. All income sources need to be within the UK.",
      list: [
        "There's no minimum income level.",
        'Customers must be registered for UK tax.',
        "Any residential mortgages must be covered by your customers' income.",
        'Payslips must show the customer and employer name, pay date, basic income, gross and net pay, and any additional payments being used in affordability assessments.',
        "We'll request an employer's reference where the customer can't supply our preferred documentation. This doesn't apply where the customer's employed in a family business",
        'Hand-written payslips are only acceptable alongside bank statements to confirm the income paid by the employer.',
        "Bank statements must show the customer's full name or initial, surname, and account number.",
        'Internet bank statements must show bank heading/name and http address.',
      ],
      table: {
        ariaLabel: 'Table for income types',
        testIdPrefix: 'income-types-table',
        width: 'auto',
        responsive: true,
        columns: [
          { displayText: 'Income Type', key: 'col-income-type' },
          { displayText: 'Acceptable?', key: 'col-acceptable' },
          { displayText: 'Main or Other', key: 'col-main-or-other' },
          { displayText: 'Key as', key: 'col-key-as' },
          { displayText: 'Evidence', key: 'col-evidence' },
        ],
        rows: [
          {
            'col-income-type':
              'Additional Duty Hours (including Additional Responsibility Hours)',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Additional Duty Hours',
            'col-evidence': 'Latest 3 months payslips',
          },
          {
            'col-income-type': 'Adoption Allowance',
            'col-acceptable': 'No',
            'col-main-or-other': '',
            'col-key-as': '',
            'col-evidence': '',
          },
          {
            'col-income-type': 'AFC Absence',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Shift Allowance',
            'col-evidence': 'Latest 3 months payslips',
          },
          {
            'col-income-type': 'Airbnb',
            'col-acceptable': 'No',
            'col-main-or-other': '',
            'col-key-as': '',
            'col-evidence': '',
          },
          {
            'col-income-type': 'Area Allowance',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Town / Area / Car Allowance',
            'col-evidence': 'Latest months payslip(s)',
          },
          {
            'col-income-type': 'Armed Forces Independence Payments (AFIP)',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Pension - Private',
            'col-evidence':
              'Latest Bank Statement (where paid gross) or Latest Pension Statement / Latest months payslip(s) or Pension P60',
          },
          {
            'col-income-type': 'Attendance Allowance (State Benefit)',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Attendance Allowance',
            'col-evidence': 'Latest Bank Statement or Benefit Award Letter',
          },
          {
            'col-income-type': 'Attendance Allowance (Turning Up To Work)',
            'col-acceptable': 'No',
            'col-main-or-other': '',
            'col-key-as': '',
            'col-evidence': '',
          },
          {
            'col-income-type':
              "Bank Holiday Pay (only if it forms part of basic pay and doesn't inflate income)",
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Main',
            'col-key-as': 'Basic Annual Income',
            'col-evidence': 'Latest months payslips',
          },
          {
            'col-income-type':
              'Basic Salary (including the probationary period of a permanent contract)',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Main',
            'col-key-as': 'Basic Annual Income',
            'col-evidence': 'Latest months payslips',
          },
          {
            'col-income-type':
              'Bereavement Allowance (previously Widows Pension. If State Widows Pension, please see below).',
            'col-acceptable': 'No',
            'col-main-or-other': '',
            'col-key-as': '',
            'col-evidence': '',
          },
          {
            'col-income-type':
              'Bonus (These must not be keyed unless they have actually been paid)',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Main',
            'col-key-as': 'Annual Guaranteed Bonus',
            'col-evidence':
              "Latest 3 months' payslips (or for incomes received less frequently than monthly all payslips showing this income for the latest 2 years)",
          },
          {
            'col-income-type': 'Bounty Payment',
            'col-acceptable': 'No',
            'col-main-or-other': '',
            'col-key-as': '',
            'col-evidence': '',
          },
          {
            'col-income-type': 'Bursary',
            'col-acceptable': 'No',
            'col-main-or-other': '',
            'col-key-as': '',
            'col-evidence': '',
          },
          {
            'col-income-type': 'Call Out',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Additional Duty Hours',
            'col-evidence': 'Latest 3 months payslips',
          },
          {
            'col-income-type': 'Car Allowance inc. Car Trade Down.',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Town / Area / Car Allowance',
            'col-evidence': 'Latest months payslips',
          },
          {
            'col-income-type': 'Carers Allowance',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Carers Allowance',
            'col-evidence': 'Latest Bank Statement or Benefit Award Letter',
          },
          {
            'col-income-type': 'Cash In Hand',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Main',
            'col-key-as': 'Basic Annual Income',
            'col-evidence': 'Latest months payslip(s) and Bank Statement',
          },
          {
            'col-income-type': 'CEA (Doctors Excellence Award)',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Main',
            'col-key-as': 'Basic Annual Income',
            'col-evidence': 'Latest months payslips',
          },
          {
            'col-income-type': 'Child Benefit',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Child Benefit',
            'col-evidence': 'Latest Bank statement or Benefit Award Letter',
          },
          {
            'col-income-type': 'Child Tax Credit',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Child Tax Credit',
            'col-evidence': 'Latest Bank statement or Benefit Award Letter',
          },
          {
            'col-income-type': 'Commission',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Main',
            'col-key-as': 'Annual Regular / Guaranteed Commission',
            'col-evidence':
              "Latest 3 months' payslips (or for incomes received less frequently than monthly all payslips showing this income for the latest 2 years)",
          },
          {
            'col-income-type': 'Competency Related Threshold Payment',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Main',
            'col-key-as': 'Annual Guaranteed Bonus',
            'col-evidence':
              "Latest 3 months' payslips (or for incomes received less frequently than monthly all payslips showing this income for the latest 2 years)",
          },
          {
            'col-income-type': 'Constant Attendance Allowance',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Constant Attendance Allowance',
            'col-evidence': 'Latest Bank Statement or Benefit Award Letter',
          },
          {
            'col-income-type': 'Continual Professional Development (CPD)',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Additional Duty Hours',
            'col-evidence': 'Latest 3 months payslips',
          },
          {
            'col-income-type': 'Council Tax Benefit',
            'col-acceptable': 'No',
            'col-main-or-other': '',
            'col-key-as': '',
            'col-evidence': '',
          },
          {
            'col-income-type': 'Country Allowance',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Town / Area / Car Allowance',
            'col-evidence': 'Latest months payslips',
          },
          {
            'col-income-type': 'Danger Allowance',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Main',
            'col-key-as': 'Annual Regular Overtime',
            'col-evidence': 'Latest 3 months payslips',
          },
          {
            'col-income-type': 'Delivery Supplement',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Main',
            'col-key-as': 'Basic Annual Income',
            'col-evidence': 'Latest months payslips',
          },
          {
            'col-income-type':
              'Disability Living Allowance - not acceptable if received for a 3rd party e.g. dependent',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Disability Living',
            'col-evidence': 'Latest Bank Statement or Benefit Award Letter',
          },
          {
            'col-income-type': 'Dividends - For Company Director',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Main',
            'col-key-as': 'Self Employed',
            'col-evidence':
              'See Limited Company Director/Shareholder under Self Employed/Contractors section',
          },
          {
            'col-income-type': 'Dividends - Investment Linked',
            'col-acceptable': 'No',
            'col-main-or-other': '',
            'col-key-as': '',
            'col-evidence': '',
          },
          {
            'col-income-type':
              'Employed in a Family Business: If less than 25% invested interest and does not receive dividends or net profit as part of their reward package (Treat as employed)',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Main',
            'col-key-as': 'Basic Annual Income',
            'col-evidence':
              'Latest months payslip(s), and corresponding bank statement(s)',
          },
          {
            'col-income-type': 'Employment & Support Allowance (ESA)',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Employment and Support Allowance',
            'col-evidence': 'Latest Bank Statement or Benefit Award Letter',
          },
          {
            'col-income-type': 'Examiners Payroll',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Additional Duty Hours',
            'col-evidence': 'Latest 3 months payslips',
          },
          {
            'col-income-type': 'Expenses',
            'col-acceptable': 'No',
            'col-main-or-other': '',
            'col-key-as': '',
            'col-evidence': '',
          },
          {
            'col-income-type': 'Fire Warden Allowance',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Additional Duty Hours',
            'col-evidence': 'Latest 3 months payslips',
          },
          {
            'col-income-type': 'First Aid Allowance',
            'col-acceptable': 'No',
            'col-main-or-other': '',
            'col-key-as': '',
            'col-evidence': '',
          },
          {
            'col-income-type': 'FIT Payment',
            'col-acceptable': 'No',
            'col-main-or-other': '',
            'col-key-as': '',
            'col-evidence': '',
          },
          {
            'col-income-type': 'Fixed Annuity Payments (Private)',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Pension - Private',
            'col-evidence':
              'Latest Bank Statement (where paid gross) or Latest Pension Statement / Latest months payslip(s) or Pension P60',
          },
          {
            'col-income-type': 'Flexible Benefit (We accept 100% of benefit)',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Colleague Flexible Benefit',
            'col-evidence': 'Latest 3 months payslips',
          },
          {
            'col-income-type': 'Flight Attendant Allowance',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Flight Pay / Allowance',
            'col-evidence': 'Latest 3 months payslips',
          },
          {
            'col-income-type': 'Footwear Allowance',
            'col-acceptable': 'No',
            'col-main-or-other': '',
            'col-key-as': '',
            'col-evidence': '',
          },
          {
            'col-income-type': 'Forklift Premium',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Additional Duty Hours',
            'col-evidence': 'Latest 3 months payslips',
          },
          {
            'col-income-type': 'Foster Care Income',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Main',
            'col-key-as': 'Self-Employed',
            'col-evidence': 'Latest 2 years tax assessments',
          },
          {
            'col-income-type': 'Gambling',
            'col-acceptable': 'No',
            'col-main-or-other': '',
            'col-key-as': '',
            'col-evidence': '',
          },
          {
            'col-income-type': 'Get You Home Allowance',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Additional Duty Hours',
            'col-evidence': 'Latest 3 months payslips',
          },
          {
            'col-income-type': 'Guardian Allowance',
            'col-acceptable': 'No',
            'col-main-or-other': '',
            'col-key-as': '',
            'col-evidence': '',
          },
          {
            'col-income-type': 'HDT Allowance (Home to Duty Travel)',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Additional Duty Hours',
            'col-evidence': 'Latest 3 months payslips',
          },
          {
            'col-income-type': 'Healthcare Allowance',
            'col-acceptable': 'No',
            'col-main-or-other': '',
            'col-key-as': '',
            'col-evidence': '',
          },
          {
            'col-income-type': 'High Cost Area',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Town / Area / Car Allowance',
            'col-evidence': 'Latest months payslips',
          },
          {
            'col-income-type': 'HM Forces - Guaranteed Income Payment (GIP)',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Pension - Private',
            'col-evidence':
              'Latest Bank Statement (where paid gross) or Latest Pension Statement / Latest months payslip(s) or Pension P60',
          },
          {
            'col-income-type':
              "Holiday Pay (only if it forms part of basic pay and doesn't inflate income)",
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Main',
            'col-key-as': 'Basic Annual Income',
            'col-evidence': 'Latest months payslips',
          },
          {
            'col-income-type':
              'Housing Allowance (where paid by employer only)',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Town / Area / Car Allowance',
            'col-evidence': 'Latest months payslips',
          },
          {
            'col-income-type': 'Housing Benefit',
            'col-acceptable': 'No',
            'col-main-or-other': '',
            'col-key-as': '',
            'col-evidence': '',
          },
          {
            'col-income-type': 'Income Support',
            'col-acceptable': 'No',
            'col-main-or-other': '',
            'col-key-as': '',
            'col-evidence': '',
          },
          {
            'col-income-type': 'Industrial Injuries Disablement Benefit',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Industrial Injuries Disablement Benefit',
            'col-evidence': 'Latest Bank Statement or Benefit Award Letter',
          },
          {
            'col-income-type': 'Insurance Payout',
            'col-acceptable': 'No',
            'col-main-or-other': '',
            'col-key-as': '',
            'col-evidence': '',
          },
          {
            'col-income-type':
              'Investment Income (Unless applicant already retired - see Pension Schemes for details)',
            'col-acceptable': 'No',
            'col-main-or-other': '',
            'col-key-as': '',
            'col-evidence': '',
          },
          {
            'col-income-type': 'Key Holder Allowance',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Additional Duty Hours',
            'col-evidence': 'Latest 3 months payslips',
          },
          {
            'col-income-type': 'Leave of Absence',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Main',
            'col-key-as': 'Basic Annual Income',
            'col-evidence': 'Latest months payslips',
          },
          {
            'col-income-type': 'Living Wage Allowance',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Main',
            'col-key-as': 'Basic Annual Income',
            'col-evidence': 'Latest months payslips',
          },
          {
            'col-income-type': 'Lodgers Income',
            'col-acceptable': 'No',
            'col-main-or-other': '',
            'col-key-as': '',
            'col-evidence': '',
          },
          {
            'col-income-type': 'London Weighting',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Town / Area / Car Allowance',
            'col-evidence': 'Latest months payslips',
          },
          {
            'col-income-type': 'Long Separation Allowance',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Town / Area / Car Allowance',
            'col-evidence': 'Latest months payslips',
          },
          {
            'col-income-type': 'Long Service Pay',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Main',
            'col-key-as': 'Basic Annual Income',
            'col-evidence': 'Latest months payslips',
          },
          {
            'col-income-type': 'Long Term Sick',
            'col-acceptable': 'No',
            'col-main-or-other': '',
            'col-key-as': '',
            'col-evidence': '',
          },
          {
            'col-income-type': 'Lottery',
            'col-acceptable': 'No',
            'col-main-or-other': '',
            'col-key-as': '',
            'col-evidence': '',
          },
          {
            'col-income-type': 'Lunch Allowance',
            'col-acceptable': 'No',
            'col-main-or-other': '',
            'col-key-as': '',
            'col-evidence': '',
          },
          {
            'col-income-type': 'Maintenance',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Maintenance',
            'col-evidence':
              'Latest 3 months Bank statements Court Order or Maintenance Assessment or letter from Child Support Agency (CSA)',
          },
          {
            'col-income-type': 'Market Supplement',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Main',
            'col-key-as': 'Basic Annual Income',
            'col-evidence': 'Latest months payslips',
          },
          {
            'col-income-type':
              'Maternity Pay - providing the applicant will return to work on the same terms.',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Main',
            'col-key-as': 'Basic Annual Income',
            'col-evidence':
              'Latest months payslip(s) received prior to commencement of maternity',
          },
          {
            'col-income-type': 'Medical Negligence Annuity Payments',
            'col-acceptable': 'No',
            'col-main-or-other': '',
            'col-key-as': '',
            'col-evidence': '',
          },
          {
            'col-income-type': 'Military Reservist Allowance',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Additional Duty Hours',
            'col-evidence': 'Latest 3 months payslips',
          },
          {
            'col-income-type': 'Mortgage Subsidy',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Mortgage Subsidy',
            'col-evidence': 'Latest 3 months payslips',
          },
          {
            'col-income-type': 'NHS Banding',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Main',
            'col-key-as': 'Basic Annual Income',
            'col-evidence': 'Latest months payslips',
          },
          {
            'col-income-type': 'NHS Enhanced Pay /Enhancements (EN) or (ENH)',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Shift Allowance',
            'col-evidence': 'Last 3 consecutive payslips',
          },
          {
            'col-income-type': 'Non Consol Pay NP',
            'col-acceptable': 'No',
            'col-main-or-other': '',
            'col-key-as': '',
            'col-evidence': '',
          },
          {
            'col-income-type': 'Nursing Bank (Main Income)',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Main',
            'col-key-as': 'Basic Annual Income',
            'col-evidence': 'Latest 3 months payslips',
          },
          {
            'col-income-type': 'Nursing Bank (Other Income)',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Nursing Bank',
            'col-evidence': 'Latest 3 months payslips',
          },
          {
            'col-income-type': 'Occupational Sick Pay',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Main',
            'col-key-as': 'Basic Annual Income',
            'col-evidence': 'Latest months payslips',
          },
          {
            'col-income-type': 'Offshore Allowance',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Additional Duty Hours',
            'col-evidence': 'Latest 3 months payslips',
          },
          {
            'col-income-type': 'On Call Allowance',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Additional Duty Hours',
            'col-evidence': 'Latest 3 months payslips',
          },
          {
            'col-income-type': 'Overtime',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Main',
            'col-key-as': 'Annual Regular Overtime',
            'col-evidence': 'Latest 3 months payslips',
          },
          {
            'col-income-type': 'Pension Supplement',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Colleague Flexible Benefits',
            'col-evidence': 'Latest 3 months payslips',
          },
          {
            'col-income-type': 'Pension Tax Credits',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Pension Credit',
            'col-evidence': 'Latest Bank Statement or Pension Statement',
          },
          {
            'col-income-type':
              'Permanent Health Insurance (PHI) The term must not extend past the final payment date for PHI if affordability is based on this income only',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Pension - Private',
            'col-evidence':
              'Latest Bank Statement (where paid gross) or Latest Pension Statement / Latest months payslip(s) or Pension P60',
          },
          {
            'col-income-type': 'Personal Independence Pay',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Personal Independence Pay',
            'col-evidence': 'Latest Bank Statement or Benefit Award Letter',
          },
          {
            'col-income-type': 'Petrol Allowance',
            'col-acceptable': 'No',
            'col-main-or-other': '',
            'col-key-as': '',
            'col-evidence': '',
          },
          {
            'col-income-type': 'Piecework',
            'col-acceptable': 'No',
            'col-main-or-other': '',
            'col-key-as': '',
            'col-evidence': '',
          },
          {
            'col-income-type': 'Private Pension / Private Widowers Pension',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Pension - Private',
            'col-evidence':
              'Latest Bank Statement (where paid gross) or Latest Pension Statement / Latest months payslip(s) or Pension P60',
          },
          {
            'col-income-type': 'Profit Related Pay',
            'col-acceptable': 'No',
            'col-main-or-other': '',
            'col-key-as': '',
            'col-evidence': '',
          },
          {
            'col-income-type': 'Project Disbursement',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Town / Area / Car Allowance',
            'col-evidence': 'Latest months payslips',
          },
          {
            'col-income-type': 'Protection of Pay Allowance',
            'col-acceptable': 'No',
            'col-main-or-other': '',
            'col-key-as': '',
            'col-evidence': '',
          },
          {
            'col-income-type':
              "Public Holiday Pay (only if it forms part of basic pay and doesn't inflate income)",
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Main',
            'col-key-as': 'Basic Annual Income',
            'col-evidence': 'Latest months payslips',
          },
          {
            'col-income-type': 'Recruitment and Retainment Pay',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Additional Duty Hours',
            'col-evidence': 'Latest 3 months payslips',
          },
          {
            'col-income-type': 'Renewable Energy',
            'col-acceptable': 'No',
            'col-main-or-other': '',
            'col-key-as': '',
            'col-evidence': '',
          },
          {
            'col-income-type': 'RHI Payment',
            'col-acceptable': 'No',
            'col-main-or-other': '',
            'col-key-as': '',
            'col-evidence': '',
          },
          {
            'col-income-type': 'Savings',
            'col-acceptable': 'No',
            'col-main-or-other': '',
            'col-key-as': '',
            'col-evidence': '',
          },
          {
            'col-income-type': 'Scholarship',
            'col-acceptable': 'No',
            'col-main-or-other': '',
            'col-key-as': '',
            'col-evidence': '',
          },
          {
            'col-income-type':
              'Scottish Island Allowance/Distant Island Allowance/Island Allowance',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Town / Area / Car Allowance',
            'col-evidence': 'Latest months payslips',
          },
          {
            'col-income-type': 'Seasonal Contract',
            'col-acceptable': 'No',
            'col-main-or-other': '',
            'col-key-as': '',
            'col-evidence': '',
          },
          {
            'col-income-type': 'Secondment Allowance',
            'col-acceptable': 'No',
            'col-main-or-other': '',
            'col-key-as': '',
            'col-evidence': '',
          },
          {
            'col-income-type': 'SEN Allowance',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Additional Duty Hours',
            'col-evidence': 'Latest 3 months payslips',
          },
          {
            'col-income-type': 'Shift Allowance',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Shift Allowance',
            'col-evidence': 'Latest 3 months payslips',
          },
          {
            'col-income-type': 'Sleepover Allowance',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Additional Duty Hours',
            'col-evidence': 'Latest 3 months payslips',
          },
          {
            'col-income-type': 'Standby Allowance',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Additional Duty Hours',
            'col-evidence': 'Latest 3 months payslips',
          },
          {
            'col-income-type': 'State Pension',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Pension - State',
            'col-evidence': 'Latest Bank Statement or Pension Statement',
          },
          {
            'col-income-type':
              'State Widows Pension. (Please see above if Bereavement Allowance).',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Pension - State',
            'col-evidence': 'Latest Bank Statement or Pension Statement',
          },
          {
            'col-income-type': 'Statutory Maternity Pay',
            'col-acceptable': 'No',
            'col-main-or-other': '',
            'col-key-as': '',
            'col-evidence': '',
          },
          {
            'col-income-type':
              'Statutory Sick Pay (if returning to work see Occupational Sick Pay)',
            'col-acceptable': 'No',
            'col-main-or-other': '',
            'col-key-as': '',
            'col-evidence': '',
          },
          {
            'col-income-type': 'Stipend (Only acceptable for Clergy)',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Main',
            'col-key-as': 'Basic Annual Income',
            'col-evidence': 'Letter of confirmation from Religious Order',
          },
          {
            'col-income-type': 'Tax Rebate',
            'col-acceptable': 'No',
            'col-main-or-other': '',
            'col-key-as': '',
            'col-evidence': '',
          },
          {
            'col-income-type': 'Teaching and Learning Responsibility (TLR)',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Main',
            'col-key-as': 'Basic Annual Income',
            'col-evidence': 'Latest months payslips',
          },
          {
            'col-income-type': 'Temporary Contract',
            'col-acceptable': 'No',
            'col-main-or-other': '',
            'col-key-as': '',
            'col-evidence': '',
          },
          {
            'col-income-type': 'Term Time Allowance',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Additional Duty Hours',
            'col-evidence': 'Latest 3 months payslips',
          },
          {
            'col-income-type': 'The Thalidomide Trust Income',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Pension - Private',
            'col-evidence':
              'Latest Bank Statement (where paid gross) or Latest Pension Statement / Latest months payslip(s) or Pension P60',
          },
          {
            'col-income-type': 'Tips',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Main',
            'col-key-as': 'Annual Regular Overtime',
            'col-evidence': 'Latest 3 months payslips',
          },
          {
            'col-income-type': 'Tool Allowance',
            'col-acceptable': 'No',
            'col-main-or-other': '',
            'col-key-as': '',
            'col-evidence': '',
          },
          {
            'col-income-type': 'Town Allowance',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Town / Area / Car Allowance',
            'col-evidence': 'Latest months payslips',
          },
          {
            'col-income-type': 'Travel Allowance',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Town / Area / Car Allowance',
            'col-evidence': 'Latest months payslips',
          },
          {
            'col-income-type': 'Tronc',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Main',
            'col-key-as': 'Annual Regular Overtime',
            'col-evidence': 'Latest 3 months payslips',
          },
          {
            'col-income-type': 'Trust Income',
            'col-acceptable': 'No',
            'col-main-or-other': '',
            'col-key-as': '',
            'col-evidence': '',
          },
          {
            'col-income-type': 'Uniform Allowance',
            'col-acceptable': 'No',
            'col-main-or-other': '',
            'col-key-as': '',
            'col-evidence': '',
          },
          {
            'col-income-type':
              'Universal Credit (The applicants on the mortgage must be the same as on the award letter and a joint income must be split between the applicants. Applicants must have an earned income for this income to be used. Any Housing Benefit shown on the award letter should be deducted from the income keyed as it will no longer apply)',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Universal Credit',
            'col-evidence': 'Latest Benefit Award Letter',
          },
          {
            'col-income-type': 'Unsocial Hours',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Shift Allowance',
            'col-evidence': 'Latest 3 months payslips',
          },
          {
            'col-income-type': 'War Disablement Pension',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Pension - War Disablement',
            'col-evidence': 'Latest Bank Statement or Pension Statement',
          },
          {
            'col-income-type': 'War Widow(er) Pension',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Pension - War Widow',
            'col-evidence': 'Latest Bank Statement or Pension Statement',
          },
          {
            'col-income-type': 'Widowed Parents Allowance',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Widowed Parents Allowance',
            'col-evidence': 'Latest Bank Statement or Pension Statement',
          },
          {
            'col-income-type': 'Widows Allowance',
            'col-acceptable': 'No',
            'col-main-or-other': '',
            'col-key-as': '',
            'col-evidence': '',
          },
          {
            'col-income-type': 'Work Outside Normal Hours',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Additional Duty Hours',
            'col-evidence': 'Latest 3 months payslips',
          },
          {
            'col-income-type': 'Working From Home Allowance',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Town / Area / Car Allowance',
            'col-evidence': 'Latest months payslips',
          },
          {
            'col-income-type': 'Working Tax Credit',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Working Tax Credit',
            'col-evidence': 'Latest Bank Statement or Benefit Award Letter',
          },
          {
            'col-income-type': 'Working Time Directive Payment (WTD)',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Other',
            'col-key-as': 'Additional Duty Hours',
            'col-evidence': 'Latest 3 months payslips',
          },
          {
            'col-income-type':
              'Zero Hours Contract (The customer must have been employed on a zero hours contract for a minimum of 12 months (with the same employer or with different employers but in the same type of work))',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Main',
            'col-key-as': 'Basic Annual Income',
            'col-evidence':
              "12 months' worth of payslips (this applies whether the applicant is paid weekly or monthly and could consist of P60 plus subsequent payslips to cover the full 12 month period)",
          },
        ],
      },
      additionalInfo: 'Additional information:',
      links: [
        {
          label: 'Length of employment/self-employment',
          url: anchorLink(anchors.income.lengthOfEmployment),
        },
        {
          label: 'Rental income',
          url: anchorLink(anchors.income.rentalIncome),
        },
        {
          label: 'Self-employed applicants',
          url: anchorLink(anchors.income.selfEmployed),
        },
        {
          label: 'Contractors',
          url: anchorLink(anchors.income.contractors),
        },
        {
          label: 'Self-employed/contractors',
          url: anchorLink(anchors.income.selfEmployedContractors),
        },
        {
          label: 'Second job',
          url: anchorLink(anchors.income.secondJob),
        },
        {
          label: 'Tax on rental income',
          url: anchorLink(anchors.income.taxOnRentalIncome),
        },
      ],
    },
    lengthOfEmployment: {
      id: anchors.income.lengthOfEmployment,
      title: 'Length of employment/ self-employment',
      text1:
        'Employed customers will be expected to have been employed for a minimum of three months in their current job.',
      text2:
        "Self-employed customers must have been self-employed for a minimum of two years and provide at least two years' worth of trading figures.",
      additionalInfo: 'Additional information:',
      links: [
        {
          label: 'Contractors',
          url: anchorLink(anchors.income.contractors),
        },
        {
          label: 'Income types',
          url: anchorLink(anchors.income.incomeTypes),
        },
        {
          label: 'Rental income',
          url: anchorLink(anchors.income.rentalIncome),
        },
        {
          label: 'Self-employed applicants',
          url: anchorLink(anchors.income.selfEmployed),
        },
        {
          label: 'Self-employed/contractors',
          url: anchorLink(anchors.income.selfEmployedContractors),
        },
        {
          label: 'Tax on rental income',
          url: anchorLink(anchors.income.taxOnRentalIncome),
        },
        {
          label: 'Affordability and sustainability',
          url: anchorLink(anchors.income.affordabilityAndSustainability),
        },
        {
          label: 'Second job',
          url: anchorLink(anchors.income.secondJob),
        },
      ],
    },
    rentalIncome: {
      id: anchors.income.rentalIncome,
      title: 'Rental income',
      textWithLink: (
        <>
          {`For employed, self-employed, and retired customers, all rental income and finance costs captured in the 'Land and property income' section of the application should match the customers' tax calculations from HMRC. You can find how to key this in our`}{' '}
          <Link external href="/public/bm-online-hints-tips.pdf">
            Hints & tips PDF{' '}
          </Link>
          (3.1MB).
        </>
      ),
      boldText:
        'To key a customer whose only source of income is from land and property',
      textItems1: [
        {
          text: "Select 'Occupation type' as 'Professional' and 'Employment type' as 'Self-Employed'.",
        },
        {
          text: "You'll need to input a year end date and figures for their most recent and previous years. The most recent date can't be older than 18 months from the date of submission.",
        },
        {
          text: "The remaining figures for net profit, turnover, gross profit, current assets, and current liabilities must be input as '£0'. Complete the 'Self-employment details' - including business name and accountant details - and the 'Add employment' section.  The land and property profit figures from the tax calculation will then need to be entered into the land and property income section.",
        },
        {
          text: "Any finance costs for the latest year will also need to be entered. The finance costs are referenced at the bottom of the tax calculation, as well on the individual's self-assessment tax return, and property income supplement (SA105).",
        },
        {
          text: "For 'Can income be verified?', you must answer 'Yes'.",
        },
      ],
      boldText2: 'For all remortgages and further advances',
      text2:
        'The current rental amount of the property should be keyed on the application as the current monthly rent paid by the tenant. ',
      boldText3: 'For a buy to let purchase and let to buy remortgage',
      textItems2: [
        {
          text: 'The current rental amount keyed will be the anticipated monthly rent of the property.',
        },
        {
          text: 'The gross monthly rent will need to be keyed into the property schedule screen for all buy to let properties owned by anyone named on the application.',
        },
        {
          text: 'A tenancy agreement or letter from the letting agent can be used to verify the rental income keyed in the Property schedule screen. ',
        },
      ],
      additionalInfo: 'Additional information:',
      links: [
        {
          label: 'Tax on rental income',
          url: anchorLink(anchors.income.taxOnRentalIncome),
        },
        {
          label: 'Affordability and sustainability',
          url: anchorLink(anchors.income.affordabilityAndSustainability),
        },
        {
          label: 'Income types',
          url: anchorLink(anchors.income.incomeTypes),
        },
        {
          label: 'Self-employed applicants',
          url: anchorLink(anchors.income.selfEmployed),
        },
        {
          label: 'Self-employed/contractors',
          url: anchorLink(anchors.income.selfEmployedContractors),
        },
        {
          label: 'Contractors',
          url: anchorLink(anchors.income.contractors),
        },
      ],
    },
    secondJob: {
      id: anchors.income.secondJob,
      title: 'Second job',
      text: "If any customer has more than one job, we'll consider the following to decide if we can take the secondary income into account.",
      list: [
        'Are the hours/days worked sustainable?',
        'Are the roles/skills similar?',
        "What's the distance between the jobs and the customer's home?",
        'How long has the customer been in both jobs?',
        'Is the salary consistent with the type of employment?',
      ],
      text2:
        "You should key the highest income as the customer's main income, and the income from the second job as additional employment.",

      additionalInfo: 'Additional information:',
      links: [
        {
          label: 'Rental income',
          url: anchorLink(anchors.income.rentalIncome),
        },
        {
          label: 'Tax on rental income',
          url: anchorLink(anchors.income.taxOnRentalIncome),
        },
        {
          label: 'Affordability and sustainability',
          url: anchorLink(anchors.income.affordabilityAndSustainability),
        },
        {
          label: 'Income types',
          url: anchorLink(anchors.income.incomeTypes),
        },
        {
          label: 'Self-employed applicants',
          url: anchorLink(anchors.income.selfEmployed),
        },
        {
          label: 'Self-employed/contractors',
          url: anchorLink(anchors.income.selfEmployedContractors),
        },
        {
          label: 'Contractors',
          url: anchorLink(anchors.income.contractors),
        },
      ],
    },
    selfEmployedApplicants: {
      id: anchors.income.selfEmployed,
      title: 'Self-employed applicants',
      text1:
        "Self-employed applicants must have been self-employed for a minimum of 2 years and be able to provide at least 2 years' worth of trading figures.",
      boldText1:
        'If any of the following applies, the applicant must be keyed as self-employed:',
      list: [
        'They have a shareholding of 25% or more.',
        'If joint applicants both own a percentage share of 25% or more between them, treat them both as self-employed.',
        'A sub-contractor who earns income from more than one contract.',
        'They have a partnership interest in a business where income is not PAYE, regardless of the percentage shareholding.',
        'They own a franchise.',
        "They're employed by a private limited company (LTD) and receive a salary (PAYE) and dividends as part of their remuneration package.",
        "They're in a limited liability partnership (LLP) and receive a share of net profits.",
      ],
      text2:
        "All self-employed income must be entered in the 'Net profit' field, except profit from UK Land & Property, which needs to be captured in the Land and property income section.",
      boldText: 'The net profit field should be completed as following:',
      list2: [
        'Limited company - key total of salary/remuneration and dividends drawn as shown on tax calculation.',
        'Partnership or LLP - key profit share percentage.',
        ' Sole trader - key net profits.',
      ],
      boldText2: 'Documentation needed:',
      list3: [
        "Latest 2 years' HM Revenue & Customs (HMRC) tax calculations and tax year overviews showing years and the applicants' name/initials.",
      ],
      boldText3:
        'Tax calculations are available in the applicants online self-assessment account. If they use these, the following details must be visible:',
      list4: [
        'HMRC logo.',
        'Unique Tax Reference (UTR).',
        'Tax year where the most recent is no older than 18 months old.',
        "Official wording that says: 'This is a copy of information held on your official online SA tax account with HMRC', and 'Submission is 100% complete.'",
      ],
      boldText4:
        "Online tax assessments from accountants' commercial software, accompanied by the corresponding tax year overviews are acceptable as long as they show:",
      list5: [
        'UTR.',
        "Customer's name.",
        'Tax year where the most recent is no older than 18 months old.',
      ],
      textItems: [
        {
          text: 'The tax due figure on the tax calculation and tax year overview will usually match. If there is a difference in the tax figure, we might need updated documents.',
        },
        {
          text: "The latest two years' full accounts must be finalised and clearly show the net profit for sole traders, the share of net profit for partnerships, or salary and dividends for directors of limited companies.",
        },
        {
          text: "Profit and loss statements alone aren't acceptable.",
        },
        {
          text: 'The year end for all documents must be the most recent and not be dated more than 18 months before the date of application.',
        },
      ],
      additionalInfo: 'Additional information:',
      links: [
        {
          label: 'Self-employed/contractors',
          url: anchorLink(anchors.income.selfEmployedContractors),
        },
        {
          label: 'Contractors',
          url: anchorLink(anchors.income.contractors),
        },
        {
          label: 'Rental income',
          url: anchorLink(anchors.income.rentalIncome),
        },
        {
          label: 'Tax on rental income',
          url: anchorLink(anchors.income.taxOnRentalIncome),
        },
        {
          label: 'Income types',
          url: anchorLink(anchors.income.incomeTypes),
        },
        {
          label: 'Affordability and sustainability',
          url: anchorLink(anchors.income.affordabilityAndSustainability),
        },
        {
          label: 'Second job',
          url: anchorLink(anchors.income.secondJob),
        },
      ],
    },
    selfEmployedContractors: {
      id: anchors.income.selfEmployedContractors,
      title: 'Self-employed/contractors',
      table: {
        ariaLabel: 'Table for self employed contractor requirements',
        testIdPrefix: 'self-employed-contractor-requirements',
        width: 'auto',
        responsive: true,
        columns: [
          { displayText: 'Income Type', key: 'col-income-type' },
          { displayText: 'Acceptable?', key: 'col-acceptable' },
          { displayText: 'Main or Other', key: 'col-main-or-other' },
          { displayText: 'Treat as', key: 'col-treat-as' },
          { displayText: 'Evidence', key: 'col-evidence' },
        ],
        rows: [
          {
            'col-income-type':
              'Contractor - If applicant earns more than £500 per day or £75,000 per annum - providing 12 months or more continuous employment with 6 months of contract remaining OR 2 years continuous service (for the last 2 years at the date of the application) in the same type of employment',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Main',
            'col-treat-as': 'Basic Annual Income',
            'col-evidence':
              'Copy of the contract and latest months personal/business bank statement(s) showing the salary credit',
          },
          {
            'col-income-type':
              'Contracts - Fixed / Short / Temporary - If Company Paying Tax (Excluding CIS contractors) - providing 12 months or more continuous employment with 6 months of the contract remaining OR 2 years continuous service (for the last 2 years at the date of the application) in the same type of employment',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Main',
            'col-treat-as': 'Basic Annual Income',
            'col-evidence': 'Latest months payslip(s)',
          },
          {
            'col-income-type':
              'Contracts Fixed / Short / Temporary - If Customer Paying Tax',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Self Employed',
            'col-treat-as': (
              <Link href={anchorLink(anchors.income.selfEmployed)}>
                See Self Employed Applicants
              </Link>
            ),
            'col-evidence':
              'Last 2 years Tax Calculations and corresponding Tax Year Overviews',
          },
          {
            'col-income-type':
              'IT Contractors - providing 12 months or more continuous employment with 6 months of the contract remaining OR 2 years continuous service (for the last 2 years at the date of the application) in the same type of employment',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Main',
            'col-treat-as': 'Basic Annual Income',
            'col-evidence':
              'Copy of the IT Contract and latest months personal/business bank statement(s) showing the salary credit',
          },
          {
            'col-income-type':
              'CIS Contractors - If Company Paying Tax key as employed providing 12 months or more continuous employment with 6 months of contract remaining OR 2 years continuous service (for the last 2 years at the date of the application) in the same type of employment',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Main',
            'col-treat-as': 'Basic Annual Income',
            'col-evidence':
              "Latest three consecutive months payslip/invoices (this applies whether the applicant is paid weekly or monthly) and three months corresponding bank statements. P60/Payslips If no income is received or the customer is unable to provide evidence of income then 'zero' income must be used for that month",
          },
          {
            'col-income-type':
              'CIS Contractors - If Customer Pays Own Tax or has more than one contract or is employed by Ltd company he/she has set up then treat as Self Employed',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Self Employed',
            'col-treat-as': (
              <Link href={anchorLink(anchors.income.selfEmployed)}>
                See Self Employed Applicants
              </Link>
            ),
            'col-evidence':
              'Last 2 years Tax Calculations and corresponding Tax Year Overviews',
          },
          {
            'col-income-type':
              'Employed in a Family Business Equal to or greater than 25% Invested interest and/or receives dividend or net profit as part of their reward package(Treat as Self Employed)',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Self Employed',
            'col-treat-as': (
              <Link href={anchorLink(anchors.income.selfEmployed)}>
                See Self Employed Applicants
              </Link>
            ),
            'col-evidence':
              'Last 2 years Tax Calculations and corresponding Tax Year Overviews',
          },
          {
            'col-income-type':
              'Ltd Company - Director/Shareholder - Wanting to use - Basic PAYE salary ONLY less than 25% shareholding (Treat as employed)',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Main',
            'col-treat-as': 'Basic Annual Income',
            'col-evidence': 'Latest months payslip(s)',
          },
          {
            'col-income-type':
              'Ltd Company - Director/Shareholder - Wanting to use - Basic PAYE salary ONLY Equal to or greater than 25% shareholding (Treat as self employed)',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Self Employed',
            'col-treat-as': (
              <Link href={anchorLink(anchors.income.selfEmployed)}>
                See Self Employed Applicants
              </Link>
            ),
            'col-evidence':
              'Last 2 years Tax Calculations and corresponding Tax Year Overviews',
          },
          {
            'col-income-type':
              'Ltd Company - Director/Shareholder - Wanting to use - Basic PAYE salary + dividends ONLY Irrespective of share holding (Treat as Self Employed)',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Self Employed',
            'col-treat-as': (
              <Link href={anchorLink(anchors.income.selfEmployed)}>
                See Self Employed Applicants
              </Link>
            ),
            'col-evidence':
              'Last 2 years Tax Calculations and corresponding Tax Year Overviews',
          },
          {
            'col-income-type':
              'Ltd Company - Director/Shareholder - Wanting to use - Basic PAYE salary + Net Profits ONLY irrespective of share holding (Treat as Self Employed) Note: Net Profits cannot be used without referral to Underwriters',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Self Employed',
            'col-treat-as': (
              <Link href={anchorLink(anchors.income.selfEmployed)}>
                See Self Employed Applicants
              </Link>
            ),
            'col-evidence':
              'Last 2 years Tax Calculations and corresponding Tax Year Overviews',
          },
          {
            'col-income-type': 'Self employed - Sole Trader',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Self Employed',
            'col-treat-as': (
              <Link href={anchorLink(anchors.income.selfEmployed)}>
                See Self Employed Applicants
              </Link>
            ),
            'col-evidence':
              'Last 2 years Tax Calculations and corresponding Tax Year Overviews',
          },
          {
            'col-income-type':
              'Self employed / Share holding of a Non Ltd Company (ie Partnership) Taxable Income',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Self Employed',
            'col-treat-as': (
              <Link href={anchorLink(anchors.income.selfEmployed)}>
                See Self Employed Applicants
              </Link>
            ),
            'col-evidence':
              'Last 2 years Tax Calculations and corresponding Tax Year Overviews',
          },
          {
            'col-income-type':
              'Partner of LLP receiving profit share as part of their salary package Irrespective of share of equity (Treat as Self employed)',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Self Employed',
            'col-treat-as': (
              <Link href={anchorLink(anchors.income.selfEmployed)}>
                See Self Employed Applicants
              </Link>
            ),
            'col-evidence':
              'Last 2 years Tax Calculations and corresponding Tax Year Overviews',
          },
          {
            'col-income-type':
              'Employee of LLP - not equity shareholding partner. (Treat as employed)',
            'col-acceptable': 'Yes',
            'col-main-or-other': 'Main',
            'col-treat-as': 'Basic Annual Income',
            'col-evidence': 'Latest months payslip(s)',
          },
          {
            'col-income-type': 'Seasonal Contract',
            'col-acceptable': 'No',
            'col-main-or-other': '',
            'col-treat-as': '',
            'col-evidence': '',
          },
          {
            'col-income-type': 'Piecework Contract',
            'col-acceptable': 'No',
            'col-main-or-other': '',
            'col-treat-as': '',
            'col-evidence': '',
          },
        ],
      },
    },
    taxOnRentalIncome: {
      id: anchors.income.taxOnRentalIncome,
      title: 'Tax on rental income',
      text1:
        "Finance costs are not tax deductible, but tax relief can be claimed at the basic rate, whatever rate of tax the customer pays. This will mean an increased tax liability for some landlords. Where applications are referred for manual review, we'll consider the additional tax liability.",
      text2:
        "Customers must get independent tax advice if they're unsure how the tax rules will affect them.",
      boldText1: 'Additional information:',
      links: [
        {
          label: 'Rental income',
          url: anchorLink(anchors.income.rentalIncome),
        },
      ],
    },
  },

  lending,
  topSlicing,
  property,
  existingCustomer,
};

export default ourCriteria;
