
type MutationObserverRef = { observer: MutationObserver}

export function mutationCallback(
  observerRef: MutationObserverRef,
  pageToAvoid: string,
  elementId: string,
) {
  return () => {
    const targetElement = document.getElementById(elementId);

    if (targetElement) {
      // only remove if we are not on the pageToAvoid - this is to NOT
      // remove the element when this function is called in development
      // with React strict mode (React calls hooks twice in strict mode)
      if (!window.location.pathname.endsWith(pageToAvoid)) {
        targetElement.remove();
      }

      // always disconnect & remove the observer if the element was found
      // if element was not removed due to current page being pageToAvoid
      // the page can call this to remove it again
      observerRef.observer.disconnect();
    }
  };
}

/**
 * Remove an element that has been added to the document body that
 * was not created or managed by React
 * @param pageToAvoid
 * @param elementId
 */
export function removeNonReactBodyElement(
  pageToAvoid: string,
  elementId: string,
) {
  const observerRef: MutationObserverRef = { observer: undefined };
  const callback = mutationCallback(observerRef, pageToAvoid, elementId);

  observerRef.observer = new MutationObserver(callback);
  observerRef.observer.observe(document.body, { childList: true });
}
