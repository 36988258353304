import { contentSectionBox } from 'client/components/infoPanel/InfoPanel.config';
import * as routes from 'client/routes/manifest';
import { AppContent } from 'content/AppContent';

import { MetaContentValues } from '../MetaContentValues';

const submittedMortgageApplications: AppContent['submittedMortgageApplications'] =
  {
    metaContent: MetaContentValues.SubmittedMortgageApplications,
    pageTitle: 'Submitted mortgage applications',
    backButtonLabel: 'Back',
    backButtonHref: routes.ContactUs,

    infoPanel: {
      contentSections: [
        {
          isAccordion: false,
          sectionID: 'submitted-mortgage-applications',
          sectionBody: [
            {
              text: 'Want an update, or need to change something?',
            },
          ],
        },
        {
          isAccordion: true,
          sectionID: 'help-submitting-documents',
          sectionHeading: 'Help submitting documents',
          headingLevel: 'h2',
          sectionBody: [
            {
              linkButton: {
                displayText: 'View our document upload guide',
                href: routes.DocumentUpload,
                external: false,
              },
            },
          ],
        },
        {
          isAccordion: true,
          sectionID: 'get-application-update',
          sectionHeading: 'To get an application update',
          headingLevel: 'h2',
          sectionBody: [
            {
              container: contentSectionBox({ marginBottom: '08' }),
              text: "Please check your emails for updates. If you can't find what you need, you can get in touch by phone.",
            },
            {
              boldText:
                'For standard buy to let or let to buy applications, call:',
            },
            {
              textWithLinks: [
                {
                  displayText: '0345 850 5000',
                  href: 'tel:03458505000',
                  external: false,
                },
                ' (01422 861005 from outside the UK).',
              ],
            },
            {
              container: contentSectionBox({ marginBottom: '08' }),
              text: "Lines are open Monday to Friday 8am to 6pm. We're not open Saturdays, Sundays, or bank holidays.",
            },
            {
              boldText: 'For portfolio landlord applications, call:',
            },
            {
              container: contentSectionBox({ marginBottom: '05' }),
              link: {
                displayText: '0345 602 0914',
                href: 'tel:03456020914',
                external: false,
              },
            },
            {
              container: contentSectionBox({ marginBottom: '08' }),
              text: "Lines are open Monday to Friday 9am to 5pm. We're not open Saturdays, Sundays, or bank holidays.",
            },
            {
              textWithLinks: [
                {
                  displayText: 'View our criteria for Portfolio landlords',
                  href: `${routes.OurCriteria}#lending`,
                  external: false,
                },
              ],
            },
          ],
        },
        {
          isAccordion: true,
          sectionID: 'make-changes-application',
          sectionHeading: 'To make changes to an application',
          headingLevel: 'h2',
          sectionBody: [
            {
              text: "You can make some changes to an application once it's been submitted. You can find these lower down this page.",
            },
            {
              text: "For changes you can make online, you'll need to sign in, retrieve the application and make the applicable changes. You'll need to produce a new quote before you submit the changes. You don't need to let us know if you've made changes to an application - we'll be in touch if we need any more information.",
            },
            {
              text: 'If you need us to make a change for you, please get in touch using the BDM contact form.',
            },
            {
              linkButton: {
                displayText: 'Go to contact form',
                href: '/public/bm-contact-form.pdf',
                external: true,
              },
              container: contentSectionBox({ marginBottom: '08' }),
            },
            {
              text: "Once you've completed the BDM contact form, please upload it to the integrated document upload on the application.",
            },
            {
              container: contentSectionBox({ marginBottom: '08' }),
              basicTable: {
                ariaLabel:
                  'Table containing options on how to update certain application information',
                testIdPrefix: 'update-application-information-table',
                width: 'auto',
                columns: [
                  { displayText: 'Detail', key: 'column-0' },
                  {
                    displayText: 'How to change - online or phone',
                    key: 'column-1',
                  },
                ],
                rows: [
                  {
                    'column-0':
                      'Property - minor amend to address or property details*',
                    'column-1': 'Upload a Contact Form or phone',
                  },
                  {
                    'column-0': 'Property - changing property to be mortgaged',
                    'column-1': 'Online - Select Revise Property Details',
                  },
                  {
                    'column-0': 'Product',
                    'column-1': 'Online - Select Quotes',
                  },
                  {
                    'column-0':
                      'Personal details (name, address*, date of birth, contact details)',
                    'column-1': 'Upload a Contact Form or phone',
                  },
                  {
                    'column-0': 'Loan amount, term or purchase price',
                    'column-1': 'Online - Select Quotes',
                  },
                  {
                    'column-0': 'Employment',
                    'column-1': 'Online or upload Contact Form',
                  },
                  {
                    'column-0': 'Direct Debit',
                    'column-1': 'Upload a Contact Form or phone',
                  },
                  {
                    'column-0': 'Credit commitments',
                    'column-1': 'Online',
                  },
                  {
                    'column-0': 'Conveyancer',
                    'column-1': 'Upload a Contact Form or phone',
                  },
                ],
              },
              text: "*Amendments to the applicant's address cannot be made on Let to Buy applications.",
            },
            {
              boldText: 'Important',
            },
            {
              text: "If you'd like to change from using free legals to a cashback product, please change the application type in the personal details section of the application from 'Remortgage' to 'Remortgage - own conveyancer' and produce a new quote on a cashback product.",
            },
            {
              container: contentSectionBox({ marginBottom: '08' }),
              text: 'You can then upload a contact form to confirm the new conveyancer details and we can update these on the application.',
            },
            {
              boldText: 'When to use the revise property button',
            },
            {
              text: 'You can change the full security address in the property details section of the application if the property the customer is purchasing has fallen through.**',
            },
            {
              list: [
                'Sign in and retrieve the application.',
                'Go to the property details section.',
                "Select 'Revise property' button.",
                'Enter new property address and any other required information.',
                'Select a new product in the quotes section and re-quote.',
                'Submit the changes.',
              ],
            },
            {
              textWithLinks: [
                "** If the property hasn't changed but an amendment to the format of the address needs to be made, please upload a contact form to the application. Do not use the Revise property option. If you need any support with making an amendment to the application, please contact our BDM team on ",
                {
                  displayText: '0345 425 5261',
                  href: 'tel:03454255261',
                  external: false,
                },
                '.',
              ],
            },
          ],
        },
        {
          isAccordion: true,
          sectionID: 'posting-documents-to-us',
          sectionHeading: 'Posting documents to us',
          headingLevel: 'h2',
          sectionBody: [
            {
              text: "You'll usually have to upload documents to us. But if we do need you to post anything to us, please send all documents to:",
            },
            {
              text: 'BM Solutions Processing',
            },
            {
              text: 'PO Box 834',
            },
            {
              text: 'Leeds',
            },
            {
              text: 'LS1 9PW',
            },
          ],
        },
      ],
    },
  };

export default submittedMortgageApplications;
