import React, { useEffect, useState } from 'react';

import { Hr } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useLocation } from '@interstellar/react-app-routing';
import { HideAt } from 'client/components/hideAt';
import { StyledLink } from 'client/components/styledLink/StyledLink';
import testIds from 'client/testIds';

import { MenuItem } from './NavigationMenu.config';
import {
  MobileMenuWidth,
  StyledAccordion,
  StyledNavBox,
  StyledNavBoxItem,
} from './NavigationMenu.styled';
import { AppLayoutContent } from '../appLayout/AppLayout.config';

function NavigationMenu(): React.JSX.Element {
  const {
    navigationMenu: { menuItems, menuTitle },
  } = useContent<AppLayoutContent>();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { pathname } = useLocation();
  const isCurrentSection = (url) => pathname.startsWith(url);

  useEffect(() => {
    // Close the menu when the location changes
    setIsMenuOpen(false);
  }, [pathname]);

  return (
    <>
      {/* mobile view */}
      <HideAt greaterThanOrEqualTo={MobileMenuWidth + 1}>
        <StyledAccordion
          label={menuTitle}
          variation="standalone"
          open={isMenuOpen}
          data-testid={testIds.navigationMenu.mobileMenu}
          onChange={(isOpen) => {
            if (isOpen !== isMenuOpen) {
              setIsMenuOpen(isOpen);
            }
          }}
        >
          {menuItems.map((item) => (
            <div key={`${item.Url}`}>
              <StyledLink
                to={item.Url}
                key={`mobile-${testIds.navigationMenu.menuItem}-${item.label}`}
                data-testid={`mobile-${testIds.navigationMenu.menuItem}-${item.label}`}
                weight={isCurrentSection(item.Url) ? 'bold' : 'normal'}
                underline={isCurrentSection(item.Url)}
                onClick={() => setIsMenuOpen(false)}
                external={item.external}
              >
                {item.label}
              </StyledLink>
              <Hr marginTop="03" marginBottom="03" />
            </div>
          ))}
        </StyledAccordion>
      </HideAt>
      {/* desktop view */}
      <HideAt lessThanOrEqualTo={MobileMenuWidth}>
        <StyledNavBox data-testid={testIds.navigationMenu.desktopMenu}>
          {menuItems.map((item: MenuItem) => (
            <StyledNavBoxItem
              key={`desktop-${testIds.navigationMenu.menuItem}-${item.label}`}
            >
              <StyledLink
                key={`desktop-${testIds.navigationMenu.menuItem}-${item.label}`}
                data-testid={`desktop-${testIds.navigationMenu.menuItem}-${item.label}`}
                weight={isCurrentSection(item.Url) ? 'bold' : 'normal'}
                underline={isCurrentSection(item.Url)}
                to={item.Url}
                external={item.external}
              >
                {item.label}
              </StyledLink>
            </StyledNavBoxItem>
          ))}
        </StyledNavBox>
      </HideAt>
    </>
  );
}

export default NavigationMenu;
