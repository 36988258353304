import React from 'react';

import { Paragraph } from '@constellation/core';
import { StyledLink } from 'client/components/styledLink/StyledLink';
import { AppContent } from 'content/AppContent';

import * as routes from '../../routes/manifest';
import { MetaContentValues } from '../MetaContentValues';

const lowerRatesForEnergyEfficientHomes: AppContent['lowerRatesForEnergyEfficientHomes'] =
  {
    metaContent: MetaContentValues.LowerRatesForEnergyEfficientHomes,

    pageTitle: 'Lower rates for energy-efficient homes',
    pageHeading: 'Lower rates for energy-efficient homes',
    backButtonLabel: 'Back',
    backButtonHref: routes.SustainabilityHub,

    infoPanel: {
      contentSections: [
        {
          sectionID: 'lower-rates-for-energy-efficient-homes',
          sectionBody: [
            {
              text: 'Customers buying or remortgaging a buy to let or let to buy property with an EPC rating of A,B, or C can qualify for lower rates on fixed-term mortgages.',
            },
            {
              boldText: 'How it works',
            },
            {
              list: [
                'You must get evidence of an A, B, or C EPC rating as part of the application process.',
                <Paragraph marginBottom="none">
                  This can be showing on the{' '}
                  <StyledLink
                    external
                    to="https://www.gov.uk/find-energy-certificate"
                  >
                    online EPC register
                  </StyledLink>
                  , a paper certificate, or a predicted energy assessment (PEA)
                  for a new build.
                </Paragraph>,
                'The certificate can be printed or saved. You must keep a copy as we might ask to see it later.',
                `These products will be labelled 'A-C EPC' on broker sourcing systems and can be identified by a product code starting with the letter G.`,
                'The offer can be withdrawn at any time.',
              ],
            },
          ],
        },
      ],
    },
  };

export default lowerRatesForEnergyEfficientHomes;
