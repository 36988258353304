import React from 'react';

import {
  ContentGroup,
  Heading,
  List,
  ListItem,
  Main,
  Strong,
  Table,
  TableCol,
  Text,
  TableRow,
  Link,
  Paragraph,
  Container,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { StyledTableCol } from 'client/components/basicTable/BasicTable.styled';
import { InfoPanel } from 'client/components/infoPanel';
import MetaTags from 'client/components/metaTags/MetaTags';
import { StyledLink } from 'client/components/styledLink/StyledLink';

import CustomTableComponent from './components/customTableComponent';
import { PrivacyContent } from './Privacy.config';
import { ItalicText, StyledSpan } from './PrivacyPage.styled';

const mapParagraphs = (items: Array<string>) =>
  items?.map((text) => (
    <Paragraph key={text} marginBottom="none" marginTop="none">
      {text}
    </Paragraph>
  ));

function PrivacyPage(): React.JSX.Element {
  const {
    metaContent,
    pageTitle,
    firstInfoPanel,
    secondInfoPanel,
    personalInformation,
    processingSpecialCategoriesTable,
    thirdInfoPanel,
    privacyTable,
    fourthInfoPanel,
    groupsOfPersonalInfoTable,
    italicTextone,
    fifthInfoPanel,
    sixthInfoPanel,
    companiesList,
    CreditList,
    sevenInfoPanel,
    generalBusiness,
    eighthInfoPanel,
    automatedDecisionsList,
    nineInfoPanel,
    listOne,
    tenInfoPanel,
    personalInformationAddress,
    dataProtectionOfficer,
    unhappyWithDataUsage,
    eleventhInfoPanel,
  } = useContent<PrivacyContent>();

  return (
    <Main>
      <MetaTags metaContent={metaContent} />

      <ContentGroup marginTop="05">
        <Heading as="h1" size="s7" marginTop="05">
          {pageTitle}
        </Heading>
        <InfoPanel
          infoPanel={firstInfoPanel}
          pageTestIds={{
            sectionHeading: 'privacy-heading',
            sectionBody: 'privacy-body',
          }}
        />
        <Container marginBottom="05">
          <Table headings={privacyTable.columns} responsive>
            <TableRow>
              <TableCol heading={privacyTable.columns[0]}>
                <Strong>{privacyTable.rows.first[0]}</Strong>
              </TableCol>
              <TableCol heading={privacyTable.columns[1]}>
                <Strong>{privacyTable.rows.first[1]}</Strong>
              </TableCol>
              <TableCol heading={privacyTable.columns[2]}>
                <Strong>{privacyTable.rows.first[2]}</Strong>
              </TableCol>
            </TableRow>
            <TableRow>
              <TableCol heading={privacyTable.columns[0]}>
                <ol start={1}>
                  {privacyTable.rows.second.personalInfoList.map(
                    (item, index) => (
                      <li
                        data-testid={`ListItem_${index}`}
                        key={`List_Item_${item.id}`}
                        style={{ marginBottom: '1rem', marginLeft: '2.5rem' }}
                      >
                        <Link
                          weight="bold"
                          href={`#${item.id}`}
                          data-testid={`jumpToASection_${index}`}
                        >
                          {item.text}
                        </Link>
                      </li>
                    ),
                  )}
                </ol>
              </TableCol>
              <TableCol heading={privacyTable.columns[1]}>
                <ol start={8}>
                  {privacyTable.rows.second.informationUsed.map(
                    (value, index) => (
                      <li
                        data-testid={`ListItem_${index}`}
                        key={`List_Item_${value.id}`}
                        style={{ marginBottom: '1rem', marginLeft: '2.5rem' }}
                      >
                        <Link
                          weight="bold"
                          href={`#${value.id}`}
                          data-testid={`jumpToASection_${index}`}
                        >
                          {value.text}
                        </Link>
                      </li>
                    ),
                  )}
                </ol>
              </TableCol>
              <TableCol heading={privacyTable.columns[2]}>
                <ol start={14}>
                  {privacyTable.rows.second.informationControl.map(
                    (individualItem, index) => (
                      <li
                        data-testid={`ListItem_${index}`}
                        key={`List_Item_${individualItem.id}`}
                        style={{ marginBottom: '1rem', marginLeft: '2.5rem' }}
                      >
                        <Link
                          weight="bold"
                          href={`#${individualItem.id}`}
                          data-testid={`jumpToASection_${index}`}
                        >
                          {individualItem.text}
                        </Link>
                      </li>
                    ),
                  )}
                </ol>
              </TableCol>
            </TableRow>
          </Table>
        </Container>

        <InfoPanel
          infoPanel={secondInfoPanel}
          pageTestIds={{
            sectionHeading: 'personal-information-heading',
            sectionBody: 'personal-information-body',
          }}
        />

        <CustomTableComponent
          caption={personalInformation.rowsOne.caption}
          rows={[
            [
              personalInformation.rowsOne.reasons,
              personalInformation.rowsOne.purposes,
              personalInformation.rowsOne.activities,
            ],
          ]}
          headers={personalInformation.header}
        />

        <CustomTableComponent
          caption={personalInformation.rowsTwo.caption}
          rows={[
            [
              personalInformation.rowsTwo.principles,
              personalInformation.rowsTwo.initiatives,
              personalInformation.rowsTwo.strategies,
            ],
          ]}
          headers={personalInformation.header}
        />

        <CustomTableComponent
          caption={personalInformation.rowsThree.caption}
          rows={[
            [
              personalInformation.rowsThree.principles,
              personalInformation.rowsThree.tasks,
              personalInformation.rowsThree.regulations,
            ],
          ]}
          headers={personalInformation.header}
        />

        <CustomTableComponent
          caption={personalInformation.rowsFour.caption}
          rows={[
            [
              personalInformation.rowsFour.principles,
              personalInformation.rowsFour.safeguards,
              personalInformation.rowsFour.protocols,
            ],
          ]}
          headers={personalInformation.header}
        />

        <CustomTableComponent
          caption={personalInformation.rowsFive.caption}
          rows={[
            [
              personalInformation.rowsFive.interestsAndDuties,
              personalInformation.rowsFive.operations,
              personalInformation.rowsFive.compliance,
            ],
            [
              personalInformation.rowsFive.commitments,
              personalInformation.rowsFive.rights,
              [],
            ],
          ]}
          headers={personalInformation.header}
        />
        <Container marginBottom="05">
          <Table
            width="fluid"
            responsive={false}
            caption={[
              <Strong key={processingSpecialCategoriesTable.caption}>
                {processingSpecialCategoriesTable.caption}
              </Strong>,
            ]}
            headings={[
              processingSpecialCategoriesTable.headers[0],
              processingSpecialCategoriesTable.headers[1],
            ]}
          >
            {processingSpecialCategoriesTable.column1.map((entry, colIndex) => (
              <TableRow key={`special-categories-row-${entry}`}>
                <TableCol key={`special-categories-header-${entry}`}>
                  <List>
                    <ListItem
                      key={`special-categories-header-list-entry-${entry}`}
                    >
                      {entry}
                    </ListItem>
                  </List>
                </TableCol>

                <TableCol key={`special-categories-data-${entry}`}>
                  <List>
                    {processingSpecialCategoriesTable.column2[colIndex].map(
                      (item) => (
                        <ListItem
                          key={`our-legitimate-interests-list-entry-${item}`}
                        >
                          {item}
                        </ListItem>
                      ),
                    )}
                  </List>
                </TableCol>
              </TableRow>
            ))}
          </Table>
        </Container>
        <InfoPanel
          infoPanel={thirdInfoPanel}
          pageTestIds={{
            sectionHeading: 'groups-of-personal-information-heading',
            sectionBody: 'groups-of-personal-information-body',
          }}
        />
        <Container marginBottom="05">
          <Table headings={groupsOfPersonalInfoTable.headers}>
            {groupsOfPersonalInfoTable.upperColumn1.map((_, entryIndex) => (
              <TableRow
                key={`upper-row-${groupsOfPersonalInfoTable.upperColumn1[entryIndex]}`}
              >
                <StyledTableCol
                  key={`upper-personal-info-type-${groupsOfPersonalInfoTable.upperColumn1[entryIndex]}`}
                  heading={groupsOfPersonalInfoTable.headers[0]}
                >
                  <Text>
                    {groupsOfPersonalInfoTable.upperColumn1[entryIndex]}
                  </Text>
                </StyledTableCol>

                <StyledTableCol
                  key={`upper-personal-info-description-${groupsOfPersonalInfoTable.upperColumn2[entryIndex]}`}
                  heading={groupsOfPersonalInfoTable.headers[1]}
                >
                  <Text>
                    {groupsOfPersonalInfoTable.upperColumn2[entryIndex]}
                  </Text>
                </StyledTableCol>
              </TableRow>
            ))}

            <TableRow key="special-categories-row">
              <StyledTableCol
                key="special-categories-type-row"
                heading={groupsOfPersonalInfoTable.headers[0]}
              >
                {groupsOfPersonalInfoTable.specialCategoriesType}
              </StyledTableCol>
              <StyledTableCol
                key="special-cateogries-descrption-row"
                heading={groupsOfPersonalInfoTable.headers[1]}
              >
                <Paragraph>
                  {groupsOfPersonalInfoTable.specialCategoriesFirstLine}
                </Paragraph>
                <List>
                  {groupsOfPersonalInfoTable.specialCategoriesList.map(
                    (category) => (
                      <ListItem key={`special-category-${category}`}>
                        {category}
                      </ListItem>
                    ),
                  )}
                </List>
                <Paragraph>
                  {groupsOfPersonalInfoTable.specialCategoriesSecondLine}
                </Paragraph>
              </StyledTableCol>
            </TableRow>

            {groupsOfPersonalInfoTable.lowerColumn1.map((_, entryIndex) => (
              <TableRow
                key={`lower-row-${groupsOfPersonalInfoTable.lowerColumn1[entryIndex]}`}
              >
                <StyledTableCol
                  key={`lower-personal-info-type-${groupsOfPersonalInfoTable.lowerColumn1[entryIndex]}`}
                  heading={groupsOfPersonalInfoTable.headers[0]}
                >
                  <Text>
                    {groupsOfPersonalInfoTable.lowerColumn1[entryIndex]}
                  </Text>
                </StyledTableCol>

                <StyledTableCol
                  key={`lower-personal-info-description-${groupsOfPersonalInfoTable.lowerColumn2[entryIndex]}`}
                  heading={groupsOfPersonalInfoTable.headers[1]}
                >
                  <Text>
                    {groupsOfPersonalInfoTable.lowerColumn2[entryIndex]}
                  </Text>
                </StyledTableCol>
              </TableRow>
            ))}
          </Table>
        </Container>
        <InfoPanel
          infoPanel={fourthInfoPanel}
          pageTestIds={{
            sectionHeading: 'collect-personal-information-heading',
            sectionBody: 'collect-personal-information-body',
          }}
        />
        <Paragraph marginBottom="none">
          {italicTextone[0]}
          <ItalicText>{italicTextone[1]}</ItalicText>
        </Paragraph>
        <InfoPanel
          infoPanel={fifthInfoPanel}
          pageTestIds={{
            sectionHeading: 'collect-personal-information-cont-heading',
            sectionBody: 'collect-personal-information-cont-body',
          }}
        />
        <List>
          {companiesList.normalOne.map((category) => (
            <ListItem key={`companiesList-one-${category}`}>
              {category}
            </ListItem>
          ))}
          {companiesList.italic.map((category) => (
            <ListItem key={`companiesList-italic-${category.text}`}>
              <>
                <Paragraph marginBottom="none">{category.text}</Paragraph>
                <ItalicText marginBottom="none">
                  {category.italicText}
                </ItalicText>
              </>
            </ListItem>
          ))}
          {companiesList.normalTwo.map((category) => (
            <ListItem key={`companiesList-two-${category}`}>
              {category}
            </ListItem>
          ))}
        </List>
        <Paragraph>
          This is to do with something called{' '}
          <StyledLink
            external
            to="https://www.bankofscotland.co.uk/aboutonline/profile-and-settings/open-banking.html"
          >
            Open Banking
          </StyledLink>
          , which gives you more freedom and control to use your own banking
          data. It can make it easier for you or your business to shop around
          for products like credit cards, savings and current accounts.
        </Paragraph>
        <InfoPanel
          infoPanel={sixthInfoPanel}
          pageTestIds={{
            sectionHeading: 'keep-your-personal-information-heading',
            sectionBody: 'keep-your-personal-information-body',
          }}
        />
        <List>
          <ListItem>{CreditList.normalOne[0]}</ListItem>
          <ListItem>
            <>
              <Paragraph marginBottom="none">
                {CreditList.italic[0].text}
              </Paragraph>
              <ItalicText marginBottom="none">
                {CreditList.italic[0].italicText}
              </ItalicText>
            </>
          </ListItem>
          <ListItem>{CreditList.normalOne[1]}</ListItem>
          <ListItem>
            <>
              <Paragraph marginBottom="none">
                {CreditList.italic[1].text}
              </Paragraph>
              <ItalicText marginBottom="none">
                {CreditList.italic[1].italicText}
              </ItalicText>
            </>
          </ListItem>
          <ListItem>{CreditList.normalOne[2]}</ListItem>
          <ListItem>
            <>
              <Paragraph marginBottom="none">
                {CreditList.italic[2].text}
              </Paragraph>
              <ItalicText marginBottom="none">
                {CreditList.italic[2].italicText}
              </ItalicText>
            </>
          </ListItem>
          <ListItem>{CreditList.normalOne[3]}</ListItem>
          <ListItem>
            <>
              <Paragraph marginBottom="none">
                {CreditList.italic[3].text}
              </Paragraph>
              <ItalicText marginBottom="none">
                {CreditList.italic[3].italicTextWithLink.partone}
                <Link
                  external
                  href={CreditList.italic[3].italicTextWithLink.link.url}
                >
                  {CreditList.italic[3].italicTextWithLink.link.displayText}
                </Link>
                {CreditList.italic[3].italicTextWithLink.parttwo}
              </ItalicText>
            </>
          </ListItem>
          <ListItem>
            <>
              <Paragraph marginBottom="none">
                {CreditList.italic[4].text}
              </Paragraph>
              <ItalicText marginBottom="none">
                {CreditList.italic[4].italicText}
              </ItalicText>
            </>
          </ListItem>
        </List>
        <InfoPanel
          infoPanel={sevenInfoPanel}
          pageTestIds={{
            sectionHeading: 'Insurers-heading',
            sectionBody: 'Insurers-body',
          }}
        />
        <List>
          <ListItem
            key={`generalBusiness-italic-${generalBusiness.italicTextOne.text}`}
          >
            <>
              <Paragraph marginBottom="none">
                {generalBusiness.italicTextOne.text}
              </Paragraph>
              <ItalicText marginBottom="none">
                {generalBusiness.italicTextOne.italicText}
              </ItalicText>
            </>
          </ListItem>

          {generalBusiness.list.map((value) => (
            <ListItem key={`generalBusiness-list-${value}`}>{value}</ListItem>
          ))}
        </List>
        <Strong>{generalBusiness.boldText}</Strong>
        <Paragraph>{generalBusiness.text}</Paragraph>
        <List>
          {generalBusiness.italicTextTwo.map((value) => (
            <ListItem key={`generalBusiness-italic-${value.text}`}>
              <>
                <Paragraph marginBottom="none">{value.text}</Paragraph>
                <ItalicText marginBottom="none">{value.italicText}</ItalicText>
              </>
            </ListItem>
          ))}
          <ListItem key={`generalBusiness-list-${generalBusiness.listText}`}>
            {generalBusiness.listText}
          </ListItem>
        </List>
        <InfoPanel
          infoPanel={eighthInfoPanel}
          pageTestIds={{
            sectionHeading: 'automated-decisions-heading',
            sectionBody: 'automated-decisions-body',
          }}
        />

        <List>
          {automatedDecisionsList.italic.map((value) => (
            <ListItem key={`automatedDecisionsList-italic-${value.text}`}>
              <>
                <Paragraph marginBottom="none">{value.text}</Paragraph>
                <ItalicText marginBottom="none">{value.italicText}</ItalicText>
              </>
            </ListItem>
          ))}
        </List>
        <Strong>{automatedDecisionsList.boldText}</Strong>
        <Paragraph>{automatedDecisionsList.text}</Paragraph>
        <List>
          <ListItem
            key={`automatedDecisionsList-italicOne-${automatedDecisionsList.italicOne.text}`}
          >
            <>
              <Paragraph marginBottom="none">
                {automatedDecisionsList.italicOne.text}
              </Paragraph>
              <ItalicText marginBottom="none">
                {automatedDecisionsList.italicOne.italicText}
              </ItalicText>
            </>
          </ListItem>

          {automatedDecisionsList.list.map((value) => (
            <ListItem key={`automatedDecisionsList-list-${value}`}>
              {value}
            </ListItem>
          ))}
        </List>
        <InfoPanel
          infoPanel={nineInfoPanel}
          pageTestIds={{
            sectionHeading: 'marketing-decisions-heading',
            sectionBody: 'marketing-decisions-body',
          }}
        />
        <List>
          {listOne.italic.map((value) => (
            <ListItem key={`List-One-italic-${value.text}`}>
              <>
                <Paragraph marginBottom="none">{value.text}</Paragraph>
                <ItalicText marginBottom="none">{value.italicText}</ItalicText>
              </>
            </ListItem>
          ))}
          <ListItem>{listOne.listText}</ListItem>
        </List>
        <Paragraph>{listOne.text}</Paragraph>
        <List>
          {listOne.linkWithText.map((value) => (
            <ListItem key={`List-One-italic-${value.text}`}>
              <Paragraph marginBottom="none">
                {value.text}
                <StyledLink external to={value.link}>
                  {value.displayText}
                </StyledLink>
                {value.textTwo}
              </Paragraph>
            </ListItem>
          ))}
        </List>
        <InfoPanel
          infoPanel={tenInfoPanel}
          pageTestIds={{
            sectionHeading: 'data-privacy-rights-heading',
            sectionBody: 'data-privacy-rights-body',
          }}
        />
        <Heading as="h2" size="s3" marginBottom="02">
          {personalInformationAddress.heading}
        </Heading>
        <Paragraph marginBottom="02">
          {personalInformationAddress.firstParagraph}
        </Paragraph>

        {mapParagraphs(personalInformationAddress.mailingAddress)}

        <Heading as="h2" size="s3" marginBottom="02" marginTop="05">
          {dataProtectionOfficer.heading}
        </Heading>
        <Paragraph marginBottom="02">
          {dataProtectionOfficer.firstParagraph}
        </Paragraph>

        {mapParagraphs(dataProtectionOfficer.mailingAddress)}

        <Heading as="h2" size="s3" marginBottom="02" marginTop="05">
          {unhappyWithDataUsage.heading}
        </Heading>
        <Paragraph marginBottom="02">
          {unhappyWithDataUsage.firstParagraph}
          <StyledSpan>{unhappyWithDataUsage.span}</StyledSpan>
          {unhappyWithDataUsage.secondParagraph}
        </Paragraph>
        <Paragraph marginBottom="02">
          {unhappyWithDataUsage.thirdParagraph}
        </Paragraph>
        {mapParagraphs(unhappyWithDataUsage.mailingAddress)}
        <InfoPanel
          infoPanel={eleventhInfoPanel}
          pageTestIds={{
            sectionHeading: 'complaint-rights-heading',
            sectionBody: 'complaint-rights-body',
          }}
        />
      </ContentGroup>
    </Main>
  );
}

export default PrivacyPage;
