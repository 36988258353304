import { DisplayText } from "@constellation/core";
import styled from 'styled-components';

type DisplayTextProps = React.ComponentProps<typeof DisplayText>

interface FlexContainerProps extends Pick<DisplayTextProps, 'marginTop' | 'marginBottom'> {
  justifyContent: string;
  flexDirection: string;
}

export const FlexContainer = styled.div<FlexContainerProps>`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.justifyContent};
  flex-direction: ${(props) => props.flexDirection};
  margin-top: ${({ theme, marginTop }) => theme[`spacing_${marginTop}`]};
  margin-bottom: ${({ theme, marginBottom }) =>
    theme[`spacing_${marginBottom}`]};
`;

export const FlexImg = styled.img<{
  maxWidth: string;
  marginLeft: string;
  marginRight: string;
  theme: any;
}>`
  max-width: ${(props) => props.maxWidth};
  display: block;
  margin-left: ${(props) => props.marginLeft};
  margin-right: ${(props) => props.marginRight};
  width: 100%;

  @media (min-width: ${(props) => props.theme.breakpoint_md}) {
    max-width: 200px;
    width: 200px;
  }
`;

interface FlexTextBoxProps extends DisplayTextProps {
  maxWidth: string;
}

export const FlexTextBox = styled.div<FlexTextBoxProps>`
  margin-top: ${({ theme, marginTop }) => theme[`spacing_${marginTop}`]};
  margin-left: ${({ theme, marginLeft }) => theme[`spacing_${marginLeft}`]};
  max-width: ${(props) => props.maxWidth};
`;
