import { AppContent } from 'content/AppContent';

import { MetaContentValues } from '../MetaContentValues';

const resources: AppContent['resources'] = {
  metaContent: MetaContentValues.resources,

  pageTitle: 'Resources',
  pageHeading: 'Resources',
  guidesAndFormsSection: {
    guidesAndFormsHeader: `Guides and forms`,
    guidesAndFormsText:
      "Here's where you'll find all our up-to-date mortgage documents. You can save them to your device or print them out individually.",
  },
  regulatoryGuidanceSection: {
    regulatoryGuidanceTitle: 'Regulatory guidance',
    businessBuyToLet: {
      title:
        'Business buy to let customer confirmation - including customer privacy notice',
      link: '/public/business-btl-customer-confirmation.pdf',
      text: '(PDF, 114KB)',
    },
    consumerBuyToLet: {
      title:
        'Consumer buy to let customer confirmation - including customer privacy notice',
      link: '/public/consumer-btl-customer-confirmation.pdf',
      text: '(PDF, 114KB)',
    },
    customersInVulnerable: {
      title: 'Customers in vulnerable circumstances',
      link: '/public/vulnerability-guidance-v3.pdf',
      text: '(PDF, 2669KB)',
    },
    gdprQa: {
      title: 'GDPR - intermediary Q&A',
      link: '/public/GDPR_QA.pdf',
      text: '(PDF, 140KB)',
    },
    gdprKeyChanges: {
      title: 'GDPR - key changes',
      link: '/public/key_changes.pdf',
      text: '(PDF, 270KB)',
    },
    mortgageInfo: {
      title: 'Information about your mortgage',
      link: '/public/propertiestobelet_mortgage_guide.pdf',
      text: '(PDF, 369KB)',
    },
    mortgageConditions: {
      title: 'Mortgage conditions',
      link: '/public/mortgage-conditions.pdf',
      text: '(PDF, 199KB)',
    },
    termsDirectlyAuthorised: {
      title:
        'Terms of business for mortgage intermediaries - directly authorised',
      link: '/public/DA-Phase-5-Final.pdf',
      text: '(PDF, 232KB)',
    },
    termsPrincipal: {
      title: 'Terms of business for mortgage intermediaries - principal',
      link: '/public/Principal-Phase-5-Final.pdf',
      text: '(PDF, 242KB)',
    },
  },
  usefulGuidesAndFormsSection: {
    usefulGuidesAndFormsTitle: 'Useful guides and forms',
    bmSolutionsOnline: {
      title: 'BM Solutions online - keying hints & tips',
      link: '/public/bm-online-hints-tips.pdf',
      text: '(PDF, 3.1MB)',
    },
    secureEmailUserGuide: {
      title: 'Secure email user guide',
      link: '/public/secure-email-user-guide.pdf',
      text: '(PDF, 279KB)',
    },
    authorisationForThirdParties: {
      title: 'Authorisation for third parties',
      link: '/public/Authorisation-for-Third-Parties.pdf',
      text: '(PDF, 143KB)',
    },
    contactForm: {
      title: 'Contact form',
      link: '/public/bm-contact-form.pdf',
      text: '(PDF, 515KB)',
    },
    identificationDeclarationForm: {
      title: 'Identification declaration form',
      link: '/public/IDDform.pdf',
      text: '(PDF, 85KB)',
    },
    valuationAppeal: {
      title: 'Valuation appeal',
      link: '/public/bm_value_appeal_form.pdf',
      text: '(PDF, 614KB)',
    },
  },
  placingBusinessSection: {
    placingBusinessTitle: 'Placing business',
    newBTLandLTBApplications: {
      title: 'New BTL and LTB applications',
      dataCaptureForm: {
        title: 'Data capture form',
        link: '/public/data-capture-form.pdf',
        text: '(PDF, 1.26MB)',
      },
      giftedDepositLetterTemplate: {
        title: 'Gifted deposit letter template',
        link: '/public/gifted_deposit_letter_template.pdf',
        text: '(PDF, 294KB)',
      },
    },
    existingCustomers: {
      title: 'Existing customers',
      furtherAdvanceDataCaptureForm: {
        title: 'Further advance - data capture form',
        link: '/public/fa-data-capture-form.pdf',
        text: '(PDF, 1MB)',
      },
      portingRequestForm: {
        title: 'Porting request form',
        link: '/public/porting-request-form.pdf',
        text: '(PDF, 518KB)',
      },
      revaluationForm: {
        title: 'Revaluation form',
        link: 'https://www.bankofscotland.co.uk/mortgages/existing-customers/bm-pt-revaluation-authority-form.html?optoutmulti=0:0|c1:1|c3:0|c5:0|c4:0|c2:0&optmessage=1',
        text: '',
      },
    },
  },
  customerInformationSection: {
    title: 'Customer information',
    creditScoringGuide: {
      title: 'Credit scoring guide',
      link: '/public/credit_scoring.pdf',
      text: '(PDF, 111KB)',
    },
  },
};

export default resources;
