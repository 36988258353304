import React from 'react';

import { Hr, Strong } from '@constellation/core';
import * as routes from 'client/routes/manifest';
import { AppContent } from 'content/AppContent';

import { MetaContentValues } from '../MetaContentValues';

const articleLetsGetRetrofitForTheFuture: AppContent['articleLetsGetRetrofitForTheFuture'] =
  {
    pageTitle: `Let's get retrofit for the future`,
    pageTitleTwo:
      'Our Landlord Retrofit Tool helps you give clients invaluable insight and support',
    backButtonLabel: 'Back',
    backButtonHref: routes.Articles,
    metaContent: MetaContentValues.ArticleLetsGetRetrofitForTheFuture,
    imgAltText: 'Head of BM Solutions',
    imgLine1: 'Leigh Church',
    imgLine2: 'Head of BM Solutions',
    boxContent: {
      paragraphOne: `BM Solutions believes in backing brokers and we know you're the mortgage expert that your landlord clients need.`,
      paragraphTwo: `That's why we're excited to have recently launched our Landlord Retrofit Tool through brokers.`,
      paragraphThree: `It can help you signpost your landlord clients to independent information about sustainability while showing you're in tune with consumer sentiment.`,
      paragraphFour: `This first-of-its-kind tool has been designed specifically for landlords, in conjunction with the Energy Saving Trust, to help them plan their energy-efficient property improvements.`,
      paragraphFive: `By introducing your clients to the Landlord Retrofit Tool, you can provide them with invaluable insights and access to grant funding they might not otherwise have discovered.`,
    },

    infoPanel: {
      contentSections: [
        {
          sectionID: 'article-section-1',
          isAccordion: false,
          headingLevel: 'h2',
          sectionBody: [
            { subheading: 'How it works' },
            {
              subheading: `The Landlord Retrofit Tool helps landlords by suggesting how they can boost their property's energy efficiency.`,
            },
            {
              text: 'Your client simply inputs a few details about the property, and their budget, and the tool will produce a personalised action plan.',
            },
            {
              text: 'It details how energy efficient their rental property currently is, its estimated energy costs and CO2 emissions.',
            },
            {
              text: `The tool highlights what specific changes they can make to increase the property's energy efficiency or lower their tenants' energy bills, the potential energy performance certificate (EPC) rating they could achieve, as well as what the work could cost.`,
            },
            {
              text: `These improvements don't need to be prohibitively expensive - we know many landlords don't have the funds to invest in large retrofitting projects. For example, the tool can show them low-cost improvements, such as energy-efficient lightbulbs or lagging a water tank, indicating how they will impact the property's EPC.`,
            },
            {
              text: 'However, feedback from landlords has already shown us that many are willing to continually invest in their properties, and they want to include energy-efficient improvements in their existing property maintenance schedules.',
            },
            {
              textWithLinks: [
                'They see it as future-proofing their portfolio and potentially adding to its value. ',
                {
                  displayText: 'Recent Rightmove research',
                  external: true,
                  href: 'https://www.rightmove.co.uk/guides/content/uploads/2023/07/Rightmove_Greener_Homes_Report_2023.pdf',
                },
                ` showed that improving a property's EPC rating from the UK average of a D to a C can boost its value by over £11,000.`,
              ],
            },
            {
              text: 'We know having a warm, dry home is detrimentally linked to the health and well-being of occupants. Where landlords improve the energy efficiency of their properties, they can be more attractive to tenants wanting cheaper bills, but also reduce the incidence of damp and mould. A landlord providing a home with cheaper bills can also feel more confident that their tenants will be able to continue to afford their rent.',
            },
            {
              text: 'The mortgage market now offers preferential rates and incentives on properties with better EPCs, and they will become more competitive over the coming years.',
            },
            { subheading: 'Access to grants' },
            {
              text: 'A unique feature of the Landlord Retrofit Tool is that it highlights which grants might be available on specific projects.',
            },
            {
              text: 'For example, the Energy Company Obligation scheme can apply to tenants in receipt of certain benefits, including housing benefit, which one in four privately renting households claim. The tool will flag if a grant could be applicable, providing tangible added value to your clients.',
            },
            { subheading: 'Benefits for the broker' },
            {
              text: 'We launched the Landlord Retrofit Tool through our broker website because you are the experts when it comes to your clients, and they look to you for support.',
            },
            {
              text: 'Whether you sit down with your client in person and demonstrate the tool with them or signpost them to it by sending a link, there are benefits for you, including:',
            },
            {
              text: (
                <>
                  <Strong>Retention opportunity:</Strong>
                  {` Introducing your landlord clients to the tool gives you a
                  reason to get in touch, and keeps you front of mind when they
                  next need advice.`}
                </>
              ),
            },
            {
              text: (
                <>
                  <Strong>Deepening your relationships:</Strong>
                  {` Including the tool as part of your customer fact-find could help you better understand your customers' wider property needs and make them aware of any grant funding they or their tenants could be eligible for.`}
                </>
              ),
            },
            {
              text: (
                <>
                  <Strong>Further advance opportunity:</Strong>
                  {` The tool lends itself to a further advance conversation if your landlord wants to make energy-efficient improvements and needs to fund the work.`}
                </>
              ),
            },
            {
              text: (
                <>
                  <Strong>Differentiate yourself:</Strong>
                  {` By sharing the tool, you can show that you are embracing sustainability and keeping up to date with innovation and consumer sentiment.`}
                </>
              ),
            },
            {
              component: <Hr />,
            },
            {
              text: (
                <>
                  <Strong>Improving quality</Strong>
                  {` the launch of the Landlord Retrofit Tool is part of our drive to improve quality in the private rented sector.`}
                </>
              ),
            },
            {
              text: `It also supports our sustainability strategy on the path towards the UK's net-zero target.`,
            },
            {
              text: 'Across the UK, the direction of travel is clear when it comes to sustainable homes, and many landlords are interested and ready to retrofit for the future, now.',
            },
            {
              text: `We've seen there are benefits to improving the energy efficiency in the private rented sector for both brokers and landlords. Ultimately a thriving, sustainable PRS benefits the 13 million UK renters that call it their home.`,
            },
            {
              linkButton: {
                displayText: 'Try the Landlord retrofit Tool',
                href: 'https://home-energy-saving-tool.bmsolutions.co.uk/WhereDoYouLive',
                external: true,
              },
            },
          ],
        },
      ],
    },
  };

export default articleLetsGetRetrofitForTheFuture;
