import React from 'react';

import { DisplayText } from '@constellation/core';
import { useScreen } from 'client/context/ScreenContext';

import { FlexContainer, FlexImg, FlexTextBox } from './RedGradientBox.styled';

type DisplayTextProps = React.ComponentProps<typeof DisplayText>;

interface RedGradientBoxProps
  extends Pick<DisplayTextProps, 'marginTop' | 'marginBottom'> {
  imgSrc: any;
  imgTestId?: string;
  imgAlt: string;
  children: any;
}

function RedGradientBox({
  imgSrc,
  imgTestId,
  imgAlt,
  children,
}: RedGradientBoxProps): React.JSX.Element {
  const { isMobile } = useScreen();

  return (
    <FlexContainer
      justifyContent="flex-end"
      flexDirection={isMobile ? 'column' : 'row'}
      marginTop="none"
      marginBottom="none"
    >
      <FlexTextBox
        marginTop={isMobile ? '04' : 'none'}
        marginLeft="none"
        maxWidth={isMobile ? '100%' : '75%'}
      >
        {children}
      </FlexTextBox>
      <FlexImg
        src={imgSrc}
        maxWidth="125px"
        marginLeft="auto"
        marginRight={isMobile ? 'auto' : '12px'}
        data-testid={imgTestId}
        alt={imgAlt}
      />
    </FlexContainer>
  );
}

export default RedGradientBox;
